import { RewardAndPunishmentApiRepository } from '@/app/infrastructures/repositories/api/RewardAndPunishmentApiRepository'
import {
  CreateProgramLevelBonusRewardAndPunishmentRequest,
  CreateProgramRewardAndPunishmentRequest,
  MoveProgramLevelRequest,
  UpdateProgramRewardAndPunishmentRequest,
} from '@/data/payload/api/RewardAndPunishmentRequest'
import { RewardAndPunishmentRepositoryInterface } from '@/data/persistences/repositories/contracts/RewardAndPunishmentRepositoryInterface'
import {
  ProgramDetail,
  ProgramLevelDetail,
  Programs,
} from '@/domain/entities/RewardAndPunishment'
import { injectable } from 'tsyringe'

@injectable()
export class RewardAndPunishmentPresenter
  implements RewardAndPunishmentRepositoryInterface {
  private repository: RewardAndPunishmentApiRepository

  constructor(repository: RewardAndPunishmentApiRepository) {
    this.repository = repository
  }

  async getAllProgram(params: Map<string, string>): Promise<Programs> {
    return this.repository.getAllProgram(params)
  }

  async createProgram(
    payload: CreateProgramRewardAndPunishmentRequest
  ): Promise<number> {
    return this.repository.createProgram(payload)
  }

  async updateProgram(
    payload: UpdateProgramRewardAndPunishmentRequest
  ): Promise<boolean> {
    return this.repository.updateProgram(payload)
  }

  async deleteProgram(id: number): Promise<boolean> {
    return this.repository.deleteProgram(id)
  }

  async getProgramDetail(
    id: number,
    params: Map<string, string | number>
  ): Promise<ProgramDetail> {
    return this.repository.getProgramDetail(id, params)
  }

  async moveLevel(payload: MoveProgramLevelRequest): Promise<boolean> {
    return this.repository.moveLevel(payload)
  }

  async deleteLevel(programId: number, levelId: number): Promise<boolean> {
    return this.repository.deleteLevel(programId, levelId)
  }

  async getLevel(
    programId: number,
    levelId: number
  ): Promise<ProgramLevelDetail> {
    return this.repository.getLevel(programId, levelId)
  }

  async createLevel(
    programId: number,
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  ): Promise<boolean> {
    return this.repository.createLevel(programId, payload)
  }

  async updateLevel(
    programId: number,
    levelId: number,
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  ): Promise<boolean> {
    return this.repository.updateLevel(programId, levelId, payload)
  }
}

import { Pagination } from './Pagination'

export class PickupTime {
  public id?: number
  public routeId?: number
  public city?: string
  public route?: string
  public periodeStart?: string
  public periodeEnd?: string
  public status?: string

  constructor(
    id?: number,
    routeId?: number,
    city?: string,
    route?: string,
    periodeStart?: string,
    periodeEnd?: string,
    status?: string
  ) {
    this.id = id
    this.routeId = routeId
    this.city = city
    this.route = route
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.status = status
  }
}

export class PickupTimes {
  public pagination?: Pagination
  public data?: PickupTime[]

  constructor(pagination: Pagination, data: PickupTime[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PickupTimeDetail {
  public dynamicPickupId?: number
  public routeId?: number
  public city?: string
  public route?: string
  public periodeStart?: string
  public periodeEnd?: string
  public timeSlotPickupId?: number
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string
  public timeSlotPickup?: TimeSlot[]

  constructor(
    dynamicPickupId?: number,
    routeId?: number,
    city?: string,
    route?: string,
    periodeStart?: string,
    periodeEnd?: string,
    timeSlotPickupId?: number,
    createdAt?: string,
    createdBy?: string,
    updatedAt?: string,
    updatedBy?: string,
    timeSlotPickup?: TimeSlot[]
  ) {
    this.dynamicPickupId = dynamicPickupId
    this.routeId = routeId
    this.city = city
    this.route = route
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.timeSlotPickupId = timeSlotPickupId
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
    this.timeSlotPickup = timeSlotPickup
  }
}

export class TimeSlot {
  public monday?: Day
  public tuesday?: Day
  public wednesday?: Day
  public thursday?: Day
  public friday?: Day
  public saturday?: Day
  public sunday?: Day

  constructor(data: {
    monday?: Day
    tuesday?: Day
    wednesday?: Day
    thursday?: Day
    friday?: Day
    saturday?: Day
    sunday?: Day
  }) {
    this.monday = data.monday
    this.tuesday = data.tuesday
    this.wednesday = data.wednesday
    this.thursday = data.thursday
    this.friday = data.friday
    this.saturday = data.saturday
    this.sunday = data.sunday
  }
}

export class Day {
  public status?: string
  public slot?: Slot[]
  public isDisabled?: boolean

  constructor(status: string, slot: Slot[], isDisabled: boolean) {
    this.status = status
    this.slot = slot
    this.isDisabled = isDisabled
  }
}

export class Slot {
  public startTime?: string
  public endTime?: string
  public pickupTimeLimit?: number
  public timeZone?: string

  constructor(
    startTime: string,
    endTime: string,
    pickupTimeLimit: number,
    timeZone: string
  ) {
    this.startTime = startTime
    this.endTime = endTime
    this.pickupTimeLimit = pickupTimeLimit
    this.timeZone = timeZone
  }
}

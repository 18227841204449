import { injectable } from 'tsyringe'
import { BaseResponse } from '@/domain/entities/BaseResponse'
import {
  Programs,
  Program,
  ProgramHistories,
  Trigger, ProgrammaticParcelPoinAndSaldos, ProgrammaticParcelPoinAndSaldoInviteeInviterData,
} from '@/domain/entities/Program'
import {
  CreateProgramaticPointRequestInterface,
  DeleteProgramaticPointRequestInterface,
  UpdateProgramaticPointRequestInterface,
} from '@/data/payload/contracts/ProgramaticPointRequest'
import {
  ProgramaticPointRepositoryInterface
} from '@/data/persistences/repositories/contracts/ProgramaticPointRepositoryInterface'
import {
  PointPurposeRepositoryInterface
} from '@/data/persistences/repositories/contracts/PointPurposeRepositoryInterface'
import { PointPurpose } from '@/domain/entities/PointPurpose'
import { AllProgrammaticPointApproval } from '@/data/payload/api/ProgramaticPointRequest';

@injectable()
export class ProgramaticPointPresenter {
  private programaticPointRepository: ProgramaticPointRepositoryInterface
  private pointPurposeRepository: PointPurposeRepositoryInterface

  constructor(
    programaticPointRepository: ProgramaticPointRepositoryInterface,
    pointPurposeRepository: PointPurposeRepositoryInterface
  ) {
    this.programaticPointRepository = programaticPointRepository
    this.pointPurposeRepository = pointPurposeRepository
  }

  public create(
    payload: CreateProgramaticPointRequestInterface
  ): Promise<BaseResponse> {
    return this.programaticPointRepository.create(payload)
  }

  public getHistory(
    programId: string,
    params: Map<string, unknown>
  ): Promise<ProgramHistories> {
    return this.programaticPointRepository.getHistory(programId, params)
  }

  public getHistoryParcelPoint(
    programId: string,
    params: Map<string, unknown>
  ): Promise<ProgramHistories> {
    return this.programaticPointRepository.getHistoryParcelPoint(
      programId,
      params
    )
  }

  public exportHistory(
    programId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.programaticPointRepository.exportHistory(programId, params)
  }

  public exportNeedApprovalAllProgrammatic(
    params: Map<string, unknown>
  ): Promise<string> {
    return this.programaticPointRepository.exportNeedApprovalAllProgrammatic(params)
  }

  public exportProgrammaticData(
    params: Map<string, unknown>
  ): Promise<string> {
    return this.programaticPointRepository.exportProgrammaticData(params)
  }

  public exportProgrammaticDataDetail(
    params: Map<string, unknown>
  ): Promise<string> {
    return this.programaticPointRepository.exportProgrammaticDataDetail(params)
  }

  public exportHistoryV2(
    programId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.programaticPointRepository.exportHistoryV2(programId, params)
  }

  public getAll(params: Map<string, any>): Promise<Programs> {
    return this.programaticPointRepository.getAll(params)
  }

  public get(programId: string): Promise<Program> {
    return this.programaticPointRepository.get(programId)
  }

  public getTriggers(): Promise<Trigger[]> {
    return this.programaticPointRepository.getTriggers()
  }

  public update(
    programId: string,
    payload: UpdateProgramaticPointRequestInterface
  ): Promise<boolean> {
    return this.programaticPointRepository.update(programId, payload)
  }

  public delete(
    programId: string,
    payload: DeleteProgramaticPointRequestInterface
  ): Promise<boolean> {
    return this.programaticPointRepository.delete(programId, payload)
  }

  public getPointPurposes(params: Map<string, any>): Promise<PointPurpose[]> {
    return this.pointPurposeRepository.getAll(params)
  }

  public getPaymentMethodList(): Promise<string[]> {
    return this.programaticPointRepository.getPaymentMethodList()
  }

  public getMembershipLevelList(): Promise<{ label: string, value: number }[]> {
    return this.programaticPointRepository.getMembershipLevelList()
  }

  public getAllProgrammaticPointAndSaldo(params: Map<string, unknown>): Promise<ProgrammaticParcelPoinAndSaldos> {
    return this.programaticPointRepository.getAllProgrammaticPointAndSaldo(params)
  }

  public getAllProgrammaticPointAndSaldoInviteeInviter(params: Map<string, unknown>): Promise<ProgrammaticParcelPoinAndSaldoInviteeInviterData> {
    return this.programaticPointRepository.getAllProgrammaticPointAndSaldoInviteeInviter(params)
  }

  public allProgrammaticPointApproval(payload: { listIDs: number[]; status: string, programmaticEligibleUserId: number }): Promise<boolean> {
    return this.programaticPointRepository.allProgrammaticPointApproval(new AllProgrammaticPointApproval(payload.status, payload.listIDs, payload.programmaticEligibleUserId))
  }
}

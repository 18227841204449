import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { Route, RouteConfig } from "vue-router";

export const pickup: RouteConfig = {
  path: '/pickup',
  component: MainLayout,
  meta: {
    title: 'Pickup Dashboard',
    icon: 'MenuIconPickup',
  },
  redirect: '/pickup/summary',
  children: [
    {
      path: 'summary',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup Summary',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_SUMMARY,
        accesstype: 'read'
      },
      children: [
        {
          path: '',
          name: 'PickupSummary',
          component: () =>
            import(
              /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/components/PickupItem/Summary/index.vue'
            ),
          meta: {
            title: 'Pickup Summary',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_SUMMARY,
            accessType: 'read',
          },
        }
      ]
    },
    {
      path: 'CRRQUE',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup CRRQUE',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CRRQUE,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupCRRQUE',
          component: () =>
            import(
              /* webpackChunkName: "PickupCRRQUE" */ '@/app/ui/views/Pickup/PickupCRRQUE/index.vue'
            ),
          meta: {
            title: 'Pickup CRRQUE',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRQUE,
            accessType: 'read',
            additional: {
              statusId: 'CRRQUE',
              pageTitle: 'CRRQUE',
              download: false,
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRQUE,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'PickupCRRQUEDetail',
              component: () =>
                import(
                  /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
                ),
              meta: {
                title: 'Shipment Tracking',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.PICKUP_CRRQUE,
                accessType: 'read',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'Corporate',
      component: {
        render: c => c('router-view')
      },
      redirect: 'PickupCorporate',
      meta: {
        title: 'Pickup Corporate',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
        accessType: 'read'
      },
      children: [
        {
          path: '',
          name: 'PickupCorporate',
          component: () =>
            import(
              /* webpackChunkName: "PickupCorporate" */ '@/app/ui/views/Pickup/PickupCorporate/index.vue'
            ),
          meta: {
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
            accessType: 'read'
          }
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupCorporateDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
            accessType: 'read',
          },
        },
        {
          path: 'nearby-courier/:latitude/:longitude',
          name: 'CorporateNearbyCourier',
          component: () =>
            import(
              /* webpackChunkName: "NearbyCourier" */ '@/app/ui/views/Pickup/NearbyCourier/index.vue'
            ),
          meta: {
            title: 'Nearby Courier',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
            accessType: 'read',
          },
        },
        {
          path: 'manual-assignee/assign/:shipmentId?',
          name: 'PickupCorporateManualAssignee',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
        {
          path: 'manual-assignee/bulk/:shipmentId',
          name: 'PickupCorporateManualAssigneeBulkOverview',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/Detail/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CORPORATE,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
      ]
    },
    {
      path: 'CRRNFD',
      name: 'CRRNFD',
      component: {
        render: c => c('router-view'),
      },
      redirect: {
        name: 'PickupCRRNFD'
      },
      meta: {
        title: 'Pickup CRRNFD',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
        accessType: 'read',
      },
      children: [
        {
          path: ':tab?',
          name: 'PickupCRRNFD',
          component: () =>
            import(
              /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/Dashboard/Tab.vue'
            ),
          meta: {
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',

          },
          children: [
            {
              path: '',
              name: 'ListPickupCRRNFD',
              component: () =>
                import(
                  /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/PickupCRRNFD/index.vue'
                ),
              meta: {
                isParent: true,
                title: 'CRRNFD',
                slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
                accessType: 'read',
                additional: {
                  statusId: 'CRRNFD',
                  pageTitle: 'CRRNFD/CNC',
                  download: false,
                  sort: true,
                  manualAssignee: true,
                },
              },
            },
            {
              path: 'reschedule',
              name: 'ListPickupCRRNFDReschedule',
              component: () =>
                import(
                  /* webpackChunkName: "PickupDashboardReschedule" */ '@/app/ui/views/Pickup/Reschedule/index.vue'
                ),
              meta: {
                title: 'Jadwal Ulang Pick Up',
                slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
                accessType: 'read',
              },
            },
          ]
        },

        {
          path: 'reschedule/detail/:rescheduleId',
          name: 'ReschedulePickupDetail',
          component: () =>
            import(
              /* webpackChunkName: "ReschedulePickupDetail" */ '@/app/ui/views/Pickup/Reschedule/Detail/index.vue'
            ),
          meta: {
            title: 'Jadwal Ulang Pick Up',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
        {
          path: 'reschedule/edit/:rescheduleId',
          name: 'ReschedulePickupEdit',
          component: () =>
            import(
              /* webpackChunkName: "ReschedulePickupEdit" */ '@/app/ui/views/Pickup/Reschedule/Edit/index.vue'
            ),
          meta: {
            title: 'Jadwal Ulang Pick Up',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupCRRNFDDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
          },
        },
        {
          path: 'nearby-courier/:latitude/:longitude',
          name: 'CRRNFDNearbyCourier',
          component: () =>
            import(
              /* webpackChunkName: "NearbyCourier" */ '@/app/ui/views/Pickup/NearbyCourier/index.vue'
            ),
          meta: {
            title: 'Nearby Courier',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
          },
        },
        {
          path: 'manual-assignee/assign/:shipmentId?',
          name: 'PickupCRRNFDManualAssignee',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
        {
          path: 'manual-assignee/bulk/:shipmentId',
          name: 'PickupCRRNFDManualAssigneeBulkOverview',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/Detail/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
            additional: {
              statusId: 'CRRNFD',
            },
          },
        },
      ],
    },
    {
      path: 'DOX',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup DOX',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_DOX,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupDOX',
          component: () =>
            import(
              /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/Dashboard/index.vue'
            ),
          meta: {
            title: 'Pickup DOX',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_DOX,
            accessType: 'read',
            additional: {
              statusId: 'DOX',
              pageTitle: 'DOX',
              download: true,
              manualAssignee: true,
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupDOXDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_DOX,
            accessType: 'read',
          },
        },
        {
          path: 'nearby-courier/:latitude/:longitude',
          name: 'DOXNearbyCourier',
          component: () =>
            import(
              /* webpackChunkName: "NearbyCourier" */ '@/app/ui/views/Pickup/NearbyCourier/index.vue'
            ),
          meta: {
            title: 'Nearby Courier',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
          },
        },
        {
          path: 'manual-assignee/:shipmentId?',
          name: 'PickupDOXManualAssignee',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_DOX,
            accessType: 'read',
            additional: {
              statusId: 'DOX',
            },
          },
        },
        {
          path: 'manual-assignee/bulk/:shipmentId',
          name: 'PickupDOXManualAssigneeBulkOverview',
          component: () =>
            import(
              /* webpackChunkName: "PickupManualAssignee" */ '@/app/ui/views/Pickup/ManualAssignee/Detail/index.vue'
            ),
          meta: {
            title: (route: Route) =>
              `Manual Assignee ${!route.params.shipmentId ? '(CRRSRC)' : ''}`,
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRNFD,
            accessType: 'read',
            additional: {
              statusId: 'DOX',
            },
          },
        },
      ],
    },
    {
      path: 'CRROTW',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup CRROTW',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupCRROTW',
          component: () =>
            import(
              /* webpackChunkName: "PickupCRROTW" */ '@/app/ui/views/Pickup/PickupCRROTW/index.vue'
            ),
          meta: {
            title: 'Pickup CRROTW',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
            accessType: 'read',
            additional: {
              statusId: 'CRROTW',
              pageTitle: 'CRROTW',
              download: false,
              statusIds: ['CRROTW', 'DOLOTW'],
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'PickupCRROTWDetail',
              component: () =>
                import(
                  /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
                ),
              meta: {
                title: 'Shipment Tracking',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
                accessType: 'read',
              },
            },
            {
              path: 'mark-as-done/:courierId',
              name: 'PickupCRROTWMarkAsDone',
              component: () =>
                import(
                  /* webpackChunkName: "PickupMarkAsDone" */ '@/app/ui/views/Pickup/MarkAsDone/index.vue'
                ),
              meta: {
                title: 'Mark As Done',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
                accessType: 'read',
                additional: {
                  statusId: ['CRROTW', 'DOLOTW'],
                },
              },
            },
          ],
        },
        {
          path: 'force-crrnfd/:shipmentId',
          name: 'PickupCRROTWForceCRRNFD',
          component: () => import(
            /* webpackChunkName: "PickupForceCRRNFD" */ '@/app/ui/views/Pickup/ForceCRRNFD/index.vue'
          ),
          meta: {
            title: 'Force CRRNFD',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRROTW,
            accessType: 'read',
            additional: {
              statusId: ['CRROTW', 'DOLOTW'],
            },
          },
        }
      ],
    },
    {
      path: 'additional-time',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup Tambah Waktu',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_ADDITIONAL_TIME,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupAdditionalTime',
          component: () =>
            import(
              /* webpackChunkName: "PickupAdditionalTime" */ '@/app/ui/views/Pickup/PickupAdditionalTime/index.vue'
            ),
          meta: {
            title: 'Pickup Tambah Waktu',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_ADDITIONAL_TIME,
            accessType: 'read',
            additional: {
              statusId: 'Pickup Additional Time',
              pageTitle: 'Pickup Additional Time',
              download: false,
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupAdditionalTimeDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_ADDITIONAL_TIME,
            accessType: 'read',
          },
        },
      ],
    },
    {
      path: 'CRRPIC',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup CRRPIC',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CRRPIC,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupCRRPIC',
          component: () =>
            import(
              /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/Dashboard/index.vue'
            ),
          meta: {
            title: 'Pickup CRRPIC',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRPIC,
            accessType: 'read',
            additional: {
              statusId: 'CRRPIC',
              pageTitle: 'CRRPIC',
              download: false,
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          component: {
            render: c => c('router-view'),
          },
          redirect: {
            name: 'PickupCRRPICDetail',
          },
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRPIC,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'PickupCRRPICDetail',
              component: () =>
                import(
                  /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
                ),
              meta: {
                title: 'Shipment Tracking',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.PICKUP_CRRPIC,
                accessType: 'read',
              },
            },
            {
              path: 'mark-as-done/:courierId',
              name: 'PickupCRRPICMarkAsDone',
              component: () =>
                import(
                  /* webpackChunkName: "PickupMarkAsDone" */ '@/app/ui/views/Pickup/MarkAsDone/index.vue'
                ),
              meta: {
                title: 'Mark As Done',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.PICKUP_CRRPIC,
                accessType: 'read',
                additional: {
                  statusId: 'CRRPIC',
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: 'CRRDON',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Pickup CRRDON',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_CRRDON,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupCRRDON',
          component: () =>
            import(
              /* webpackChunkName: "PickupDashboard" */ '@/app/ui/views/Pickup/Dashboard/index.vue'
            ),
          meta: {
            title: 'Pickup CRRDON',
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRDON,
            accessType: 'read',
            additional: {
              statusId: 'CRRDON',
              pageTitle: 'CRRDON',
              download: false,
            },
          },
        },
        {
          path: 'shipment/cancel-pickup/:shipmentId',
          name: 'CancelPickup',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRDON,
            accessType: 'read',
            additional: {
              isCancel: true
            },
          },
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupCRRDONDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_CRRDON,
            accessType: 'read',
          },
        },
      ],
    },
  ],
}

import { Utils } from '@/data/infrastructures/misc/Utils'
import { Pagination } from '@/domain/entities/Pagination'
import {
  DetailTopupSaldo,
  DetailTopupSaldoForm,
  RequestTopupSaldoData,
  RequestTopupSaldoHistory,
  RequestTopupSaldoHistoryList,
  RequestTopupSaldoList,
  ResultUploadBulkyRequestTopUp,
  DetailUploadBulkyRequestTopUp,
  UploadBulky,
  HistoryUploadBulky,
  dataHistoryUploadBulky,
} from '@/domain/entities/RequestTopupSaldo'
import {
  HistoryRequestTopupSaldo,
  ListRequestTopupSaldo,
  DetailUploadBulkyRequestTopUpInterface,
  PreviewUploadBulkySaldoInterface,
  HistoryUploadBulkyResponseInterface,
  DataHistoryUploadBulkyResponseInterface,
} from '@/data/responses/contracts/RequestTopupSaldoInterface'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class RequestTopupSaldoMapper extends BaseResponseMapper {
  public convertRequestTopupSaldoListFromApi(
    result: AxiosResponse
  ): RequestTopupSaldoList {
    const {
      data: { data, pagination },
    } = result
    return new RequestTopupSaldoList(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: ListRequestTopupSaldo) => {
        return new RequestTopupSaldoData(
          item.customer_id,
          item.balance_id,
          item.balance_type_id,
          item.balance_status_id,
          item.balance_amount,
          item.phone_number,
          item.created_at
        )
      })
    )
  }

  public convertRequestTopupSaldoHistoryFromApi(
    result: AxiosResponse
  ): RequestTopupSaldoHistoryList {
    const {
      data: { data, pagination, phone_number },
    } = result
    return new RequestTopupSaldoHistoryList(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: HistoryRequestTopupSaldo) => {
        return new RequestTopupSaldoHistory(
          item.approval,
          item.balance_id,
          item.balance_status_id,
          item.customer_id,
          item.phone_number,
          item.total_saldo_request,
          item.current_balance,
          item.requestor,
          item.note,
          item.created_at,
          item.balance_type_id
        )
      }),
      phone_number
    )
  }

  public convertDetailTopupSaldoFormFromApi(
    result: AxiosResponse
  ): DetailTopupSaldoForm {
    const { data } = result
    return new DetailTopupSaldoForm(
      new DetailTopupSaldo(
        data.balance_id,
        data.customer_id,
        data.amount_requested,
        data.note,
        data.actor,
        data.balance_type_id,
        data.balance_status_id,
        data.created_at,
        data.history_created_at,
      )
    )
  }

  public convertUploadBulkyFromApi(result: AxiosResponse): UploadBulky {
    const { data, pagination, metadata } = <PreviewUploadBulkySaldoInterface>(
      result.data
    )
    return new UploadBulky(
      new ResultUploadBulkyRequestTopUp(
        metadata?.filename || '',
        Number(metadata?.total_valid_rows),
        Number(metadata?.total_invalid_rows),
        Number(metadata?.total_valid_amount_negative),
        Number(metadata?.total_valid_amount_positive),
        Number(metadata?.total_invalid_amount_negative),
        Number(metadata?.total_invalid_amount_positive),
      ),
      data?.map((val: DetailUploadBulkyRequestTopUpInterface | null) => {
        return new DetailUploadBulkyRequestTopUp(
          Number(val?.customer_id),
          Number(val?.amount),
          val?.note || '',
          val?.is_valid || false,
          val?.invalid_reason || '',
          Number(val?.row_number)
        )
      }),
      new Pagination(pagination.page, pagination.per_page, pagination.total)
    )
  }

  public convertHistoryUploadBulky(result: AxiosResponse): HistoryUploadBulky {
    const { data, pagination } = <HistoryUploadBulkyResponseInterface>(
      result.data
    )

    return new HistoryUploadBulky(
      data.map((val: DataHistoryUploadBulkyResponseInterface) => {
        return new dataHistoryUploadBulky(
          val.id,
          val.filename,
          val.actor,
          val.failed_customer_ids,
          val.created_at
        )
      }),
      new Pagination(pagination.page, pagination.per_page, pagination.total)
    )
  }
}

import { DependencyContainer } from 'tsyringe'
import { AuthDataMapper } from '@/data/persistences/mappers/AuthDataMapper'
import { ProgramaticPointMapper } from '@/data/persistences/mappers/ProgramaticPointMapper'
import { PointPurposeMapper } from '@/data/persistences/mappers/PointPurposeMapper'
import { BannerMapper } from '@/data/persistences/mappers/BannerMapper'
import { CourierMapper } from '@/data/persistences/mappers/CourierMapper'
import { SaldoPaymentMapper } from '@/data/persistences/mappers/SaldoPaymentMapper'
import { UserManagementMapper } from '@/data/persistences/mappers/UserManagementMapper'
import { FAQMapper } from '@/data/persistences/mappers/FAQMapper'
import { PNMapper } from '@/data/persistences/mappers/PNMapper'
import { ClaimMapper } from '@/data/persistences/mappers/ClaimMapper'
import { PublicVoucherMapper } from '@/data/persistences/mappers/PublicVoucherMapper'
import { ProgrammaticVoucherMapper } from '@/data/persistences/mappers/ProgrammaticVoucherMapper'
import { BrandMapper } from '@/data/persistences/mappers/BrandDataMapper'
import { MerchantMapper } from '@/data/persistences/mappers/MerchantMapper'
import { RouteMapper } from '@/data/persistences/mappers/RouteMapper'
import { ProductMapper } from '@/data/persistences/mappers/ProductMapper'
import { OrderMapper } from '@/data/persistences/mappers/OrderMapper'
import { DriverScoreboardMapper } from '@/data/persistences/mappers/DriverScoreboardMapper'
import { PODTrackerMapper } from '@/data/persistences/mappers/PODTrackerMapper'
import { SelectedMerchantMapper} from '@/data/persistences/mappers/SelectedMerchantMapper'
import { CategorySectionMapper } from '@/data/persistences/mappers/CategorySectionMapper'
import { CODTrackerMapper } from '@/data/persistences/mappers/CODTrackerMapper'
import { WithdrawalMapper } from '@/data/persistences/mappers/WithdrawalMapper'
import { PickupMapper } from '@/data/persistences/mappers/PickupMapper'
import { HomepageBannerMapper } from '@/data/persistences/mappers/HomepageBannerMapper'
import { DriverAnnouncementMapper } from '@/data/persistences/mappers/DriverAnnouncementMapper'
import { ManageCourierMapper } from '@/data/persistences/mappers/ManageCourierMapper'
import { ManageCustomerMapper } from '@/data/persistences/mappers/ManageCustomerMapper'
import { WhiteSellerMapper } from '@/data/persistences/mappers/WhiteSellerMapper'
import { PayrollMapper } from '@/data/persistences/mappers/PayrollMapper'
import { ManualdjustmentBalanceMapper } from '@/data/persistences/mappers/ManualAdjustmentMapperMapper'
import { ManageDayMapper } from '@/data/persistences/mappers/ManageDayMapper'
import { ManageCaptainMapper } from '@/data/persistences/mappers/ManageCaptainMapper'
import { ManageDataPayrollMapper } from "@/data/persistences/mappers/ManageDataPayrollMapper";
import { PickupLocationMapper } from '@/data/persistences/mappers/PickupLocationMapper'
import { ScheduleOrderMapper } from '@/data/persistences/mappers/ScheduleOrderMapper'
import { ManageCourierDedicatedMapper } from '@/data/persistences/mappers/ManageCourierDedicatedMapper'
import { LoyaltyProgramGetPointRulesMapper } from '@/data/persistences/mappers/LoyaltyProgramGetPointRulesMapper'
import { MembershipFormulaMapper } from '@/data/persistences/mappers/MembershipFormulaMapper'
import { LoyaltyProgramMembershipMapper } from '@/data/persistences/mappers/LoyaltyProgramMembershipMapper'
import { AdjustmentParcelPoinMapper } from '@/data/persistences/mappers/AdjustmentParcelPoinMapper'
import { ReschedulePickupMapper } from '@/data/persistences/mappers/ReschedulePickupMapper'
import { ApprovalAdjustSaldoMapper } from '@/data/persistences/mappers/ApprovalAdjustSaldoMapper'
import { PickupRadiusValidationMapper } from '@/data/persistences/mappers/PickupRadiusValidationMapper'
import { UpdateAppVersionMapper} from '@/data/persistences/mappers/UpdateAppVersionMapper'
import { PackageMapper } from '@/data/persistences/mappers/PackageMapper'
import { PhoneCodeMapper } from '@/data/persistences/mappers/PhoneCodeMapper'
import { CancelValidationMapper } from '@/data/persistences/mappers/CancelValidationMapper'
import { PickupTimeManagementMapper } from '@/data/persistences/mappers/PickupTimeManagementMapper'
import { RewardAndPunishmentMapper } from '@/data/persistences/mappers/RewardAndPunishmentMapper'
import { ShuttleMapper } from '@/data/persistences/mappers/ShuttleMapper'
import { CODMapper } from '@/data/persistences/mappers/CODMapper'
import { VoucherConfigSKUMapper } from '@/data/persistences/mappers/VoucherConfigSKUMapper'
import { VoucherConfigSetLimitMapper } from '@/data/persistences/mappers/VoucherConfigSetLimitMapper'
import { VoucherConfigHistoryBulkyMapper } from '@/data/persistences/mappers/VoucherConfigHistoryBulkyMapper'

export class MapperModule {
  public static init(container: DependencyContainer): void {
    container.register<AuthDataMapper>(AuthDataMapper, {
      useClass: AuthDataMapper,
    })
    container.register<ProgramaticPointMapper>(ProgramaticPointMapper, {
      useClass: ProgramaticPointMapper,
    })
    container.register<PointPurposeMapper>(PointPurposeMapper, {
      useClass: PointPurposeMapper,
    })
    container.register<BannerMapper>(BannerMapper, {
      useClass: BannerMapper,
    })
    container.register<CourierMapper>(CourierMapper, {
      useClass: CourierMapper,
    })
    container.register<SaldoPaymentMapper>(SaldoPaymentMapper, {
      useClass: SaldoPaymentMapper,
    })
    container.register<UserManagementMapper>(UserManagementMapper, {
      useClass: UserManagementMapper,
    })
    container.register<FAQMapper>(FAQMapper, {
      useClass: FAQMapper,
    })
    container.register<PNMapper>(PNMapper, {
      useClass: PNMapper,
    })
    container.register<ClaimMapper>(ClaimMapper, {
      useClass: ClaimMapper,
    })
    container.register<PublicVoucherMapper>(PublicVoucherMapper, {
      useClass: PublicVoucherMapper,
    })
    container.register<ProgrammaticVoucherMapper>(ProgrammaticVoucherMapper, {
      useClass: ProgrammaticVoucherMapper,
    })
    container.register<BrandMapper>(BrandMapper, {
      useClass: BrandMapper,
    })
    container.register<MerchantMapper>(MerchantMapper, {
      useClass: MerchantMapper,
    })
    container.register<RouteMapper>(RouteMapper, {
      useClass: RouteMapper,
    })
    container.register<ProductMapper>(ProductMapper, {
      useClass: ProductMapper,
    })
    container.register<OrderMapper>(OrderMapper, {
      useClass: OrderMapper,
    })
    container.register<DriverScoreboardMapper>(DriverScoreboardMapper, {
      useClass: DriverScoreboardMapper,
    })
    container.register<PODTrackerMapper>(PODTrackerMapper, {
      useClass: PODTrackerMapper,
    })
    container.register<SelectedMerchantMapper>(SelectedMerchantMapper, {
      useClass: SelectedMerchantMapper,
    })
    container.register<CategorySectionMapper>(CategorySectionMapper, {
      useClass: CategorySectionMapper,
    })
    container.register<CODTrackerMapper>(CODTrackerMapper, {
      useClass: CODTrackerMapper,
    })
    container.register<WithdrawalMapper>(WithdrawalMapper, {
      useClass: WithdrawalMapper
    })
    container.register<PickupMapper>(PickupMapper, {
      useClass: PickupMapper
    })
    container.register<HomepageBannerMapper>(HomepageBannerMapper, {
      useClass: HomepageBannerMapper
    })
    container.register<DriverAnnouncementMapper>(DriverAnnouncementMapper, {
      useClass: DriverAnnouncementMapper
    })
    container.register<ManageCourierMapper>(ManageCourierMapper, {
      useClass: ManageCourierMapper
    })
    container.register<ManageCustomerMapper>(ManageCustomerMapper, {
      useClass: ManageCustomerMapper
    })
    container.register<WhiteSellerMapper>(WhiteSellerMapper, {
      useClass: WhiteSellerMapper
    })
    container.register<PayrollMapper>(PayrollMapper, {
      useClass: PayrollMapper
    })
    container.register<ManualdjustmentBalanceMapper>(ManualdjustmentBalanceMapper, {
      useClass: ManualdjustmentBalanceMapper
    })
    container.register<ManageDayMapper>(ManageDayMapper, {
      useClass: ManageDayMapper
    })
    container.register<ManageCaptainMapper>(ManageCaptainMapper, {
      useClass: ManageCaptainMapper
    })
    container.register<ManageDataPayrollMapper>(ManageDataPayrollMapper, {
      useClass: ManageDataPayrollMapper
    })
    container.register<PickupLocationMapper>(PickupLocationMapper, {
      useClass: PickupLocationMapper
    })
    container.register<ScheduleOrderMapper>(ScheduleOrderMapper, {
      useClass: ScheduleOrderMapper
    })
    container.register<ManageCourierDedicatedMapper>(ManageCourierDedicatedMapper, {
      useClass: ManageCourierDedicatedMapper
    })
    container.register<LoyaltyProgramGetPointRulesMapper>(LoyaltyProgramGetPointRulesMapper, {
        useClass: LoyaltyProgramGetPointRulesMapper
      })
    container.register<MembershipFormulaMapper>(MembershipFormulaMapper, {
      useClass: MembershipFormulaMapper
    })
    container.register<LoyaltyProgramMembershipMapper>(LoyaltyProgramMembershipMapper, {
      useClass: LoyaltyProgramMembershipMapper
    })
    container.register<AdjustmentParcelPoinMapper>(AdjustmentParcelPoinMapper, {
      useClass: AdjustmentParcelPoinMapper
    })
    container.register<ReschedulePickupMapper>(ReschedulePickupMapper, {
      useClass: ReschedulePickupMapper
    })
    container.register<ApprovalAdjustSaldoMapper>(ApprovalAdjustSaldoMapper, {
      useClass: ApprovalAdjustSaldoMapper
    })
    container.register<PickupRadiusValidationMapper>(PickupRadiusValidationMapper, {
      useClass: PickupRadiusValidationMapper
    })
    container.register<UpdateAppVersionMapper>(UpdateAppVersionMapper, {
      useClass: UpdateAppVersionMapper
    })
    container.register<PackageMapper>(PackageMapper, {
      useClass: PackageMapper
    })
    container.register<PhoneCodeMapper>(PhoneCodeMapper, {
      useClass: PhoneCodeMapper
    })
    container.register<CancelValidationMapper>(CancelValidationMapper, {
      useClass: CancelValidationMapper
    })
    container.register<PickupTimeManagementMapper>(PickupTimeManagementMapper, {
      useClass: PickupTimeManagementMapper
    })
    container.register<RewardAndPunishmentMapper>(RewardAndPunishmentMapper, {
      useClass: RewardAndPunishmentMapper
    })
    container.register<ShuttleMapper>(ShuttleMapper, {
      useClass: ShuttleMapper
    })
    container.register<CODMapper>(CODMapper, {
      useClass: CODMapper
    })
    container.register<VoucherConfigSKUMapper>(VoucherConfigSKUMapper, {
      useClass: VoucherConfigSKUMapper
    })
    container.register<VoucherConfigSetLimitMapper>(VoucherConfigSetLimitMapper, {
      useClass: VoucherConfigSetLimitMapper
    })
    container.register<VoucherConfigHistoryBulkyMapper>(VoucherConfigHistoryBulkyMapper, {
      useClass: VoucherConfigHistoryBulkyMapper
    })
  }
}

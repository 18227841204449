export class Endpoints {
  public static baseUrl = process.env.VUE_APP_API_URL

  // tslint:disable-next-line: max-classes-per-file
  public static Route = class {
    public static CMS_MY_PROFILE = '/v1/account/profile/me'
    public static CMS_AUTH = '/v1/account/auth'
    public static ACCOUNT_AGENT = '/v1/account/agent'
    public static PROGRAMATIC_POINT = '/v1/shipment/programmatic/point'
    public static PROGRAMATIC_POINT_V2 = '/v2/shipment/programmatic/point'
    public static PAYMENT_SALDO = '/v1/shipment/admin/payment-saldo'
    public static POINT_PURPOSE = '/v1/shipment/point/purpose'
    public static BANNER = '/v1/shipment/admin/banner'
    public static AGENT = '/v1/shipment/agent'
    public static V2AGENT = '/v2/shipment/agent'
    public static ADMIN = '/v1/account/admin'
    public static CMS_MENU = '/v1/account/cms/menu'
    public static FAQ = '/v1/shipment/faq'
    public static FAQ_SUB_CATEGORY = '/v1/shipment/faq/sub-category'
    public static FAQ_IMAGE_CATEGORY = '/v1/shipment/upload/image'
    public static FAQ_STARRED_QUESTION = '/v1/shipment/faq/star'
    public static PUSH_NOTIFICATION = '/v1/account/notification'
    public static PUSH_NOTIFICATION_CATEGORY_OPTIONS =
      '/v1/account/notification/bulk/category/option'
    public static CLAIM = '/v1/shipment/claim'
    public static PUBLIC_VOUCHER = '/v1/shipment/public_voucher'
    public static PUBLIC_VOUCHER_PARTNER = '/v1/shipment/voucher-partner'
    public static PUBLIC_VOUCHER_V2 = '/v2/shipment/public_voucher'
    public static PROGRAMATIC_VOUCHER = '/v1/shipment/programmatic/voucher'
    public static PROGRAMATIC_VOUCHER_V2 = '/v2/shipment/programmatic/voucher'
    public static BRAND = '/v1/shop/brand'
    public static MERCHANT = '/v1/shop/merchant'
    public static MERCHANT_ADMIN = '/v1/shop/admin/merchant'
    public static V2MERCHANT = '/v2/shop/merchant'
    public static ROUTE = '/v1/shipment/admin/route'
    public static PRODUCT = '/v1/shop/admin/product'
    public static PRODUCT_CATEGORY = '/v1/shop/admin/category'
    public static HISTORY_LOG_BULK_UPLOAD_PRODUCT = '/v1/shop/admin/history-log'
    public static ORDER_MANAGEMENT = '/v1/shipment/shop/order'
    public static CAMPAIGN = '/v1/shipment/campaign'
    public static CANCEL_ORDER = '/v1/shipment/admin/shipment/cancel'
    public static V3INVOICE = '/v3/shipment/admin/invoice'
    public static DRIVER_SCORE = '/v1/shipment/admin/score'
    public static COURIER = '/v1/account/courier'
    public static CUSTOMER = '/v1/account/customer'
    public static CUSTOMER_BLOCKED = '/v1/account/customer/block'
    public static CUSTOMER_UNBLOCKED = '/v1/account/customer/unblock'
    public static CUSTOMER_HISTORY_STATUS = '/v1/account/customer/ban-history'
    public static POD = '/v1/shipment/pod'
    public static GET_PACKAGE_POD = '/v1/shipment/package/detail-proof'
    public static UPLOAD_IMAGE_POD = '/inbound-admin/delivery/proof'
    public static V2POD = '/v2/shipment/pod'
    public static SELECTED_MERCHANT = '/v1/shop/admin/selected-merchant'
    public static CATEGORY_SECTION = '/v1/shop/admin/section'
    public static COD = '/v1/shipment/admin/shipment/cash-on-delivery'
    public static WITHDRAWAL = '/v1/shipment/withdrawal'
    public static PICKUP = '/v1/shipment/admin/data'
    public static PICKUP_V2 = '/v2/shipment/admin/data'
    public static SNAPSHOT = '/v1/dispatch/offer/snapshot'
    public static PICKUP_SUMMARY = '/inbound-admin/pickup/summary'
    public static PICKUP_LIST_CRRNFD = '/inbound-admin/pickup/data'
    public static PICKUP_FORCE_DETAIL = '/inbound-admin/pickup/detail'
    public static PICKUP_DEDICATED_CRROTW =
      '/inbound-admin/pickup/dedicated/crrotw/submit'
    public static PICKUP_EXPORT = '/v1/shipment/admin/delivery-order/export'
    public static NEARBY_COURIER = '/v1/dispatch/courier/nearest'
    public static MANUAL_ASSIGNEE = '/v1/shipment/task/assign/pickup'
    public static HOMEPAGE_BANNER = '/v1/shop/admin/banner'
    public static PICKUP_MARK_AS_DONE = '/v1/shipment/task/pickup/done'
    public static PICKUP_GROUP_MARK_AS_DONE =
      '/v1/shipment/task/pickup-group/done'
    public static DRIVER_ANNOUNCEMENT = '/v1/account/announcement'
    public static DRIVER_ANNOUNCEMENTV2 = '/inbound-admin/announcement'
    public static UPLOAD_IMAGE = '/inbound-admin/images/upload'
    public static MANAGE_COURIER = '/v1/account/courier'
    public static MANAGE_COURIER_LEAVE_SCHEDULE = '/v1/account/courier/leave-schedule'
    public static MANAGE_COURIER_V2 = '/v2/account/courier'
    public static MIX_PANEL = '/v1/publisher/logging'
    public static WHITE_SELLER = '/inbound-admin/whitelist/agent'
    public static PAYROLL = '/inbound-admin/payroll'
    public static PAYROLLV1 = '/v1/payroll'
    public static ADJUSTMENT = '/v1/shipment/payment/balance'
    public static POPUP_BANNER = '/v1/shipment/admin/pop-up-banner'
    public static PICKUP_LOCATION = '/v1/shipment/admin/pick-up-location'
    public static PARTNER = '/v1/shipment/partner'
    public static SHIPMENT_ADMIN = '/v1/shipment/admin'
    public static AREA_DEDICATED = '/v1/account/area_dedicated'
    public static LOYALTY_MEMBERSHIP = '/v1/account/membership-program'
    public static PARCEL_POIN = '/v1/shipment/parcel-point'
    public static ADJUSTMENT_PARCEL_POIN =
      '/v1/shipment/parcel-point/adjustment'
    public static LOYALTY_GET_POINT_RULES = '/v1/shipment/point-rules'
    public static MEMBERSHIP_FORMULA = '/v1/shipment/membership-point-formula'
    public static COURIER_DEDICATED = '/v1/account/courier_dedicated'
    public static GEO_ADDRESS = '/v1/account/geo-address'
    public static RESCHEDULE_PICKUP = '/v1/shipment/admin/reschedule-pickup'
    public static MANUAL_ASSIGNEE_PICKUP = '/v1/shipment/admin/assign/pickup'
    public static PICKUP_ADDITIONAL_TIME = '/v1/shipment/admin/additional-pickup-time'
    public static EXPORT_PICKUP_LIST = '/v1/shipment/admin/pickup/export'
    public static MEMBERSHIP_EXTRA_MULTIPLIER = '/v1/shipment/membership-extra-multiplier'
    public static SHIPMENT_PICKUP_LOG = '/v1/shipment/admin/pickup-log'
    public static UPDATE_APP_VERSION = '/v1/account/app/upgrade'
    public static VOUCHER_CONFIG = '/v1/shipment/voucher'
    public static PACKAGE = '/v1/shipment/package'
    public static PHONE_CODE = '/v1/shipment/phone-code'
    public static TRUCK_VENDOR = '/v1/account/truck-vendor'
    public static CANCEL_VALIDATION = '/v1/shipment/admin/cancel'
    public static PICKUP_OTW = '/v1/shipment/admin/pickup-otw'
    public static PICKUP_FORCE_CRRCNC = '/v1/shipment/task/pickup/cancel'
    public static DYNAMIC_PICKUP = '/v1/shipment/dynamic-pickup'
    public static SHIPMENT_PICKUP = '/v1/shipment/admin/pickup'
    public static REWARD_AND_PUNISHMENT = '/v1/shipment/reward-punishment'
    public static SHUTTLE = '/v1/shipment/shuttle'
    public static COD_PAYMENT_SETTING = '/v1/shipment/cod-payment-setting'
    public static PICKUP_QUEUE = '/v1/shipment/admin/pickup-queue'
    public static PICKUP_NFD = '/v1/shipment/admin/pickup-crrnfd'
    public static MANUAL_ASSIGN_EXPORT_LOG = '/inbound-admin/pickup/manual-assign/history/export'
    public static PICKUP_CORPORATE = '/v1/shipment/admin/pickup-corporate'
  }
}

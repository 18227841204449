import { Utils } from '@/app/infrastructures/misc'
import { RewardAndPunishMentProgramLevelDetailResponse } from '@/data/responses/contracts/RewardAndPunishmentResponse'
import { Pagination } from '@/domain/entities/Pagination'
import {
  Program,
  ProgramDetail,
  Programs,
  ProgramLevel,
  ProgramLevels,
  ProgramLevelDetail,
  CourierRating,
  RejectIgnoreSuccessPickup,
  ProgramLevelBonus,
  ProgramLevelBonusDay,
  ProgramLevelBonusData,
  ProgramLevelBonusFee,
  ProgramLevelPenalty,
  ProgramLevelPenaltyData,
} from '@/domain/entities/RewardAndPunishment'
import { AxiosResponse } from 'axios'

export class RewardAndPunishmentMapper {
  convertProgramsFromApi(result: AxiosResponse): Programs {
    const {
      data: { pagination, data },
    } = result

    return new Programs(
      Utils.toInstance(new Pagination(), pagination),
      data.map((program: string) => {
        return Utils.toInstance(new Program(), program)
      })
    )
  }

  convertIdProgramsFromApi(result: AxiosResponse): number {
    const {
      data: { reward_punishment_id },
    } = result

    return reward_punishment_id
  }

  convertProgramDetailFromApi(result: AxiosResponse): ProgramDetail {
    const { data } = result

    return new ProgramDetail(
      data.program_id,
      data.program_name,
      data.periode_start,
      data.periode_end,
      data.evaluation_schedule,
      data.status,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      new ProgramLevels(
        new Pagination(
          data.level_data.Pagination.page,
          data.level_data.Pagination.per_page,
          data.level_data.Pagination.total
        ),
        data.level_data.data.map((level: string) => {
          return Utils.toInstance(new ProgramLevel(), level)
        })
      )
    )
  }

  convertProgramLevelDetailFromApi(result: AxiosResponse): ProgramLevelDetail {
    const {
      data,
    }: { data: RewardAndPunishMentProgramLevelDetailResponse } = result
  
    return new ProgramLevelDetail(
      data.program_name,
      data.period_start,
      data.period_end,
      data.program_status,
      data.evaluation_schedule,
      data.created_at,
      data.updated_at,
      data.created_by,
      data.updated_by,
      data.level_name,
      new CourierRating(
        data.courier_rating.rate,
        data.courier_rating.operator_origin,
        data.courier_rating.operator_text
      ),
      new RejectIgnoreSuccessPickup(
        data.reject_ignore.percentage,
        data.reject_ignore.operator_origin,
        data.reject_ignore.operator_text
      ),
      new RejectIgnoreSuccessPickup(
        data.success_pickup.percentage,
        data.success_pickup.operator_origin,
        data.success_pickup.operator_text
      ),
      new ProgramLevelBonus(
        new ProgramLevelBonusDay(
          data.bonus.weekday.is_pickup,
          data.bonus.weekday.is_delivery,
          data.bonus.weekday.withdrawal_fee,
          data.bonus.weekday.data?.map(bonus => {
            return new ProgramLevelBonusData(
              bonus.bonus_id,
              bonus.is_randomize,
              bonus.time_pickup_minimum,
              bonus.time_pickup_maximum,
              bonus.bonus_fee.map(bonusFee => {
                return new ProgramLevelBonusFee(
                  bonusFee.bonus_fee_id,
                  bonusFee.fee,
                  bonusFee.quota,
                  bonusFee.is_unlimited
                )
              })
            )
          }) || []
        ),
        new ProgramLevelBonusDay(
          data.bonus.weekend.is_pickup,
          data.bonus.weekend.is_delivery,
          data.bonus.weekend.withdrawal_fee,
          data.bonus.weekend.data?.map(bonus => {
            return new ProgramLevelBonusData(
              bonus.bonus_id,
              bonus.is_randomize,
              bonus.time_pickup_minimum,
              bonus.time_pickup_maximum,
              bonus.bonus_fee.map(bonusFee => {
                return new ProgramLevelBonusFee(
                  bonusFee.bonus_fee_id,
                  bonusFee.fee,
                  bonusFee.quota,
                  bonusFee.is_unlimited
                )
              })
            )
          }) || []
        )
      ),
      new ProgramLevelPenalty(
        data.penalty.is_freeze,
        data.penalty.list?.map(listData => {
          return new ProgramLevelPenaltyData(
            listData.penalty_id,
            listData.time_pikup_minimum,
            listData.time_pikup_maximum,
            listData.penalty_amount
          )
        }) || []
      )
    )
  }
}

import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { Pagination } from '@/domain/entities/Pagination'
import {
  Program,
  Programs,
  ProgramHistories,
  ProgramHistory,
  Trigger,
  ProgrammaticParcelPoinAndSaldos,
  ProgrammaticParcelPoinAndSaldoPagination,
  ProgrammaticParcelPoinAndSaldo,
  ProgrammaticParcelPoinAndSaldoInviteeInviterData,
  ProgrammaticParcelPoinAndSaldoInviteeInviterPagination,
  ProgrammaticParcelPoinAndSaldoInviteeInviter,
} from '@/domain/entities/Program'
import { OriginRoute } from '@/domain/entities/Route'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'

export class ProgramaticPointMapper extends BaseResponseMapper {
  public convertProgramHistoriesFromApi(
    result: AxiosResponse
  ): ProgramHistories {
    const {
      data: { pagination, data },
    } = result
    return new ProgramHistories(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (history: Record<string, never>) =>
          new ProgramHistory(
            history.programmatic_point_history_id,
            history.programmatic_point_name,
            history.customer_id,
            history.customer_name,
            history.customer_email,
            history.city,
            history.point_created_at,
            history.point_expired_at,
            history.point_initial_amount,
            history.point_spent_amount,
            history.point_disbursed_at
          )
      )
    )
  }

  public convertProgramsFromApi(result: AxiosResponse): Programs {
    const {
      data: { pagination, data },
    } = result
    return new Programs(
      Utils.toInstance(new Pagination(), pagination),
      data.map((program: Record<string, any>) => {
        try {
          const period =
            program.programmatic_point_trigger === 'ON_SCHEDULE'
              ? dayjs(program.scheduled_at).format('DD MMMM YYYY')
              : `${dayjs(program.start_date).format('DD MMMM YYYY')} - ${dayjs(
                  program.end_date
                ).format('DD MMMM YYYY')}`
          return new Program(
            program.programmatic_point_id,
            program.programmatic_point_name,
            period,
            program.programmatic_point_target,
            program.programmatic_point_trigger,
            undefined,
            program.amount,
            program.status,
            program.created_at,
            program.updated_at,
            program.programmatic_point_purpose,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            program.percent_of_transaction,
            program.max_amount_given,
            undefined,
            undefined,
            undefined,
            program.membership_level,
            program.budget_amount,
            program.budget_used,
            program.updated_by,
            program.point_value,
          )
        } catch (error) {
          console.error(error)
        }
      })
    )
  }

  public convertProgramFromApi(result: AxiosResponse): Program {
    const { data } = result

    return new Program(
      data.programmatic_point_id,
      data.programmatic_point_name,
      undefined,
      data.programmatic_point_target,
      data.programmatic_point_trigger,
      data.route.map(
        (item: Record<string, any>) =>
          new OriginRoute(item.route_id, undefined, item.city)
      ),
      data.point_value,
      data.status,
      data.created_at,
      data.updated_at,
      data.point_purpose,
      data.expiry_day,
      data.limit_per_day,
      data.start_date,
      data.end_date,
      data.selected_users,
      data.scheduled_at,
      data.invoice_source,
      data.payment_method_used,
      data.minimum_transaction,
      data.percent_of_transaction,
      data.max_amount_given,
      data.expired_date,
      data.limit_per_user,
      data.budget_source,
      data.programmatic_point_data?.membership_levels
        .filter(
          (ml: Record<string, string | number | boolean>) =>
            ml.selected === true
        )
        .map((ml: Record<string, string>) => ({
          label: ml.level_name.toUpperCase(),
          value: ml.id,
        })),
      data.budget_amount,
      data.budget_used,
      data.updated_by,
      undefined,
      data.extra_rules,
      data.point_purpose_percentage,
      data.disburse_type,
      data.point_disburse_date,
      data.point_values,
      data.limit_program,
      data.limit_user,
      data.disburse_status,
      undefined,
      data.extra_rules.total_amount,
      data.extra_rules.duration_total_amount,
      data.extra_rules.total_transaction,
      data.extra_rules.duration_total_transaction,
      data.extra_rules.type_validation,
      [data.eligible_rules.status],
      data.eligible_rules.shipment_type,
      data.eligible_rules.product_type,
      data.eligible_rules.minimum_transaction,
      undefined,
      data.point_values.amount_inviter,
      data.amount_disburse_rules.inviter?.disburse_to,
      data.amount_disburse_rules.inviter?.approval_type,
      data.point_values.amount_invitee,
      data.amount_disburse_rules.invitee?.disburse_to,
      data.amount_disburse_rules.invitee?.approval_type,
      data.point_values.amount,
      data.amount_disburse_rules.general?.disburse_to,
      data.amount_disburse_rules.general?.approval_type,
      data.point_values.inviter_percentage,
      data.point_values.max_amount_inviter_percentage,
      data.point_values.invitee_percentage,
      data.point_values.max_amount_invitee_percentage,
      data.point_values.general_percentage,
      data.point_values.max_amount_general_percentage,
      data.disburse_type,
      data.disburse_status,
      data.point_disburse_date,
      data.limit_program.duration,
      data.limit_program.total_number,
      data.limit_program.total_amount,
      data.limit_user.duration,
      data.limit_user.total_number,
      data.limit_user.total_amount,
      data.device_id_limit_customer,
      data.amount_disburse_rules.inviter?.disburse_to,
      data.amount_disburse_rules.inviter?.approval_type,
      data.amount_disburse_rules.invitee?.disburse_to,
      data.amount_disburse_rules.invitee?.approval_type,
      data.amount_disburse_rules.general?.disburse_to,
      data.amount_disburse_rules.general?.approval_type,
      data.amount_disburse_rules.invitee?.disburse_type,
      data.amount_disburse_rules.invitee?.disburse_status,
      data.amount_disburse_rules.invitee?.disburse_specific_date,
      data.amount_disburse_rules.inviter?.disburse_type,
      data.amount_disburse_rules.inviter?.disburse_status,
      data.amount_disburse_rules.inviter?.disburse_specific_date,
      data.amount_disburse_rules.general?.disburse_type,
      data.amount_disburse_rules.general?.disburse_status,
      data.amount_disburse_rules.general?.disburse_specific_date,
      data.amount_disburse_rules.invitee?.disburse_type,
      data.amount_disburse_rules.invitee?.disburse_status,
      data.amount_disburse_rules.invitee?.disburse_specific_date,
      data.amount_disburse_rules.inviter?.disburse_type,
      data.amount_disburse_rules.inviter?.disburse_status,
      data.amount_disburse_rules.inviter?.disburse_specific_date,
      data.amount_disburse_rules.general?.disburse_type,
      data.amount_disburse_rules.general?.disburse_status,
      data.amount_disburse_rules.general?.disburse_specific_date,
    )
  }

  public convertTriggersFromApi(result: AxiosResponse): Trigger[] {
    const { data } = result
    return data.map(
      (trigger: Record<string, never>) =>
        new Trigger(trigger.trigger_id, trigger.trigger_name)
    )
  }

  public convertMembershipLevelFromApi(result: AxiosResponse): { label: string; value: number }[] {
    const {
      data: { data },
    } = result
    return data.map((level: Record<string, string>) => ({
      label: level.level_name.toUpperCase(),
      value: level.level_id,
    }))
  }

  public convertOriginRouteFromApi(result: AxiosResponse): OriginRoute[] {
    const {
      data: { data },
    } = result
    return data.map(
      (route: Record<string, never>) =>
        new OriginRoute(
          route.route_id,
          route.lc,
          route.city,
          route.route,
          route.is_origin,
          route.is_pickup,
          route.is_shop
        )
    )
  }

  public convertProgrammaticPointAndSaldoFromApi(result: AxiosResponse): ProgrammaticParcelPoinAndSaldos {
    const {
      data: { pagination, data },
    } = result

    return new ProgrammaticParcelPoinAndSaldos(
      Utils.toInstance(new ProgrammaticParcelPoinAndSaldoPagination(), pagination),
      (data || []).map((val: {
        programmatic_eligible_user_id: number;
        programmatic_point_id: number;
        programmatic_point_name: string;
        customer_id: number;
        customer_name: string;
        invitee_inviter: string;
        point: number;
        period: string;
        period_date: string;
        status: string;
        disburse_to: string;
        has_child: boolean;
      }) => {
        return new ProgrammaticParcelPoinAndSaldo(
          val.programmatic_eligible_user_id,
          val.programmatic_point_id,
          val.programmatic_point_name,
          val.customer_id,
          val.customer_name,
          val.invitee_inviter,
          val.point,
          val.period,
          val.period_date,
          val.status,
          val.disburse_to,
          val.has_child,
        )
      })
    )
  }

  public convertProgrammaticPointAndSaldoInviteeInviterFromApi(result: AxiosResponse): ProgrammaticParcelPoinAndSaldoInviteeInviterData {
    const {
      data: { pagination, data },
    } = result

    return new ProgrammaticParcelPoinAndSaldoInviteeInviterData(
      Utils.toInstance(new ProgrammaticParcelPoinAndSaldoInviteeInviterPagination(), pagination),
      (data || []).map((val: {
        programmatic_point_history_id: number;
        programmatic_point_name: string;
        disburse_to: string;
        customer_id: number;
        invitee_customer_id: number;
        package_id: string;
        bkd_date: string;
        pod_date: string;
        amount_paid: number;
        percentage: number;
        total_earning: number;
        status: string;
        approved_by: string;
        fixed_amount: number;
        shipping_fee: number,
      }) => {
        return new ProgrammaticParcelPoinAndSaldoInviteeInviter(
          val.programmatic_point_history_id,
          val.programmatic_point_name,
          val.disburse_to,
          val.customer_id,
          val.invitee_customer_id,
          val.package_id,
          val.bkd_date,
          val.pod_date,
          val.amount_paid,
          val.percentage,
          val.total_earning,
          val.status,
          val.approved_by,
          val.fixed_amount,
          val.shipping_fee,
        )
      })
    )
  }
}

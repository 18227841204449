import { Pagination } from './Pagination'

export class Program {
  public id?: number
  public programName?: string
  public periodeStart?: string
  public periodeEnd?: string
  public status?: string
  public evaluationSchedule?: string
  public createdBy?: string
  public createdAt?: string
  public updatedBy?: string
  public updatedAt?: string

  constructor(
    id?: number,
    programName?: string,
    periodeStart?: string,
    periodeEnd?: string,
    status?: string,
    evaluationSchedule?: string,
    createdBy?: string,
    createdAt?: string,
    updatedBy?: string,
    updatedAt?: string
  ) {
    this.id = id
    this.programName = programName
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.status = status
    this.evaluationSchedule = evaluationSchedule
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
  }
}

export class Programs {
  public pagination?: Pagination
  public data?: Program[]

  constructor(pagination?: Pagination, data?: Program[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ProgramDetail {
  public programId?: number
  public programName?: string
  public periodeStart?: string
  public periodeEnd?: string
  public evaluationSchedule?: string
  public status?: string
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string
  public levelData?: ProgramLevels

  constructor(
    programId?: number,
    programName?: string,
    periodeStart?: string,
    periodeEnd?: string,
    evaluationSchedule?: string,
    status?: string,
    createdAt?: string,
    createdBy?: string,
    updatedAt?: string,
    updatedBy?: string,
    levelData?: ProgramLevels
  ) {
    this.programId = programId
    this.programName = programName
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.evaluationSchedule = evaluationSchedule
    this.status = status
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
    this.levelData = levelData
  }
}

export class ProgramLevel {
  public levelId?: number
  public status?: boolean
  public levelName?: string
  public createdAt?: string
  public updatedAt?: string

  constructor(
    levelId?: number,
    status?: boolean,
    levelName?: string,
    createdAt?: string,
    updatedAt?: string
  ) {
    this.levelId = levelId
    this.status = status
    this.levelName = levelName
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export class ProgramLevels {
  public pagination?: Pagination
  public data?: ProgramLevel[]

  constructor(pagination?: Pagination, data?: ProgramLevel[]) {
    this.pagination = pagination
    this.data = data || []
  }
}

export class CourierRating {
  public rate?: number
  public operatorText?: string
  public operatorOrigin?: string

  constructor(rate?: number, operatorOrigin?: string, operatorText?: string) {
    this.rate = rate
    this.operatorOrigin = operatorOrigin
    this.operatorText = operatorText
  }
}

export class RejectIgnoreSuccessPickup {
  public percentage?: number
  public operatorText?: string
  public operatorOrigin?: string

  constructor(
    percentage?: number,
    operatorOrigin?: string,
    operatorText?: string
  ) {
    this.percentage = percentage
    this.operatorOrigin = operatorOrigin
    this.operatorText = operatorText
  }
}

export class ProgramLevelBonusFee {
  public bonusFeeId?: number
  public fee?: number
  public quota?: number
  public isUnlimited?: boolean

  constructor(
    bonusFeeId?: number,
    fee?: number,
    quota?: number,
    isUnlimited?: boolean
  ) {
    this.bonusFeeId = bonusFeeId
    this.fee = fee
    this.quota = quota
    this.isUnlimited = isUnlimited
  }
}

export class ProgramLevelBonusData {
  public bonusId?: number
  public isRandomize?: boolean
  public timePickupMinimum?: number
  public timePickupMaximum?: number
  public bonusFee?: ProgramLevelBonusFee[]

  constructor(
    bonusId?: number,
    isRandomize?: boolean,
    timePickupMinimum?: number,
    timePickupMaximum?: number,
    bonusFee?: ProgramLevelBonusFee[]
  ) {
    this.bonusFee = bonusFee
    this.isRandomize = isRandomize
    this.timePickupMaximum = timePickupMaximum
    this.timePickupMinimum = timePickupMinimum
    this.bonusId = bonusId
  }
}

export class ProgramLevelBonusDay {
  public isPickup?: boolean
  public isDelivery?: boolean
  public withdrawalFee?: number
  public data?: ProgramLevelBonusData[]

  constructor(
    isPickup?: boolean,
    isDelivery?: boolean,
    withdrawalFee?: number,
    data?: ProgramLevelBonusData[]
  ) {
    this.isPickup = isPickup
    this.isDelivery = isDelivery
    this.withdrawalFee = withdrawalFee
    this.data = data
  }
}

export class ProgramLevelBonus {
  public weekday?: ProgramLevelBonusDay
  public weekend?: ProgramLevelBonusDay

  constructor(weekday?: ProgramLevelBonusDay, weekend?: ProgramLevelBonusDay) {
    this.weekday = weekday
    this.weekend = weekend
  }
}

export class ProgramLevelPenaltyData {
  public penaltyId?: number
  public timePickupMinimum?: number
  public timePickupMaximum?: number
  public penaltyAmount?: number

  constructor(
    penaltyId?: number,
    timePickupMinimum?: number,
    timePickupMaximum?: number,
    penaltyAmount?: number
  ) {
    this.penaltyId = penaltyId
    this.timePickupMinimum = timePickupMinimum
    this.timePickupMaximum = timePickupMaximum
    this.penaltyAmount = penaltyAmount
  }
}

export class ProgramLevelPenalty {
  public isFreeze?: boolean
  public list?: ProgramLevelPenaltyData[]

  constructor(isFreeze?: boolean, list?: ProgramLevelPenaltyData[]) {
    this.isFreeze = isFreeze
    this.list = list
  }
}

export class ProgramLevelDetail {
  public programName?: string
  public periodeStart?: string
  public periodeEnd?: string
  public status?: string
  public evaluationSchedule?: string
  public createdAt?: string
  public updatedAt?: string
  public createdBy?: string
  public updatedBy?: string
  public levelName?: string
  public courierRating?: CourierRating
  public rejectIgnore?: RejectIgnoreSuccessPickup
  public successPickup?: RejectIgnoreSuccessPickup
  public bonus?: ProgramLevelBonus
  public penalty?: ProgramLevelPenalty

  constructor(
    programName?: string,
    periodeStart?: string,
    periodeEnd?: string,
    status?: string,
    evaluationSchedule?: string,
    createdAt?: string,
    updatedAt?: string,
    createdBy?: string,
    updatedBy?: string,
    levelName?: string,
    courierRating?: CourierRating,
    rejectIgnore?: RejectIgnoreSuccessPickup,
    successPickup?: RejectIgnoreSuccessPickup,
    bonus?: ProgramLevelBonus,
    penalty?: ProgramLevelPenalty
  ) {
    this.programName = programName
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.status = status
    this.evaluationSchedule = evaluationSchedule
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.levelName = levelName
    this.courierRating = courierRating
    this.rejectIgnore = rejectIgnore
    this.successPickup = successPickup
    this.bonus = bonus
    this.penalty = penalty
  }
}

import { DependencyContainer } from 'tsyringe'
import ApiService from '../services/ApiService'
import { AuthDataMapper } from '@/data/persistences/mappers/AuthDataMapper'
import { ProgramaticPointMapper } from '@/data/persistences/mappers/ProgramaticPointMapper'
import { AuthApiRepository } from '@/app/infrastructures/repositories/api/AuthApiRepository'
import { ProgramaticPointApiRepository } from '@/app/infrastructures/repositories/api/ProgramaticPointApiRepository'
import { PointPurposeApiRepository } from '@/app/infrastructures/repositories/api/PointPurposeApiRepository'
import { PointPurposeMapper } from '@/data/persistences/mappers/PointPurposeMapper'
import { BannerApiRepository } from '@/app/infrastructures/repositories/api/BannerApiRepository'
import { BannerMapper } from '@/data/persistences/mappers/BannerMapper'
import { POSApiRepository } from '@/app/infrastructures/repositories/api/POSApiRepository'
import { POSMapper } from '@/data/persistences/mappers/POSMapper'
import { CourierApiRepository } from '@/app/infrastructures/repositories/api/CourierApiRepository'
import { CourierMapper } from '@/data/persistences/mappers/CourierMapper'
import { SaldoPaymentApiRepository } from '@/app/infrastructures/repositories/api/SaldoPaymentApiRepository'
import { SaldoPaymentMapper } from '@/data/persistences/mappers/SaldoPaymentMapper'
import { UserManagementApiRepository } from '@/app/infrastructures/repositories/api/UserManagementApiRepository'
import { UserManagementMapper } from '@/data/persistences/mappers/UserManagementMapper'
import { FAQApiRepository } from '@/app/infrastructures/repositories/api/FAQApiRepository'
import { FAQMapper } from '@/data/persistences/mappers/FAQMapper'
import { PNApiRepository } from '@/app/infrastructures/repositories/api/PNApiRepository'
import { PNMapper } from '@/data/persistences/mappers/PNMapper'
import { ClaimApiRepository } from '@/app/infrastructures/repositories/api/ClaimApiRepository'
import { ClaimMapper } from '@/data/persistences/mappers/ClaimMapper'
import { PublicVoucherApiRepository } from '@/app/infrastructures/repositories/api/PublicVoucherApiRepository'
import { PublicVoucherMapper } from '@/data/persistences/mappers/PublicVoucherMapper'
import { ProgrammaticVoucherApiRepository } from '@/app/infrastructures/repositories/api/ProgrammaticVoucherApiRepository'
import { ProgrammaticVoucherMapper } from '@/data/persistences/mappers/ProgrammaticVoucherMapper'
import { BrandApiRepository } from '@/app/infrastructures/repositories/api/BrandApiRepository'
import { BrandMapper } from '@/data/persistences/mappers/BrandDataMapper'
import { MerchantApiRepository } from '@/app/infrastructures/repositories/api/MerchantApiRepository'
import { MerchantMapper } from '@/data/persistences/mappers/MerchantMapper'
import { RouteApiRepository } from '@/app/infrastructures/repositories/api/RouteApiRepository'
import { RouteMapper } from '@/data/persistences/mappers/RouteMapper'
import { ProductApiRepository } from '@/app/infrastructures/repositories/api/ProductApiRepository'
import { ProductMapper } from '@/data/persistences/mappers/ProductMapper'
import { OrderApiRepository } from '@/app/infrastructures/repositories/api/OrderApiRepository'
import { OrderMapper } from '@/data/persistences/mappers/OrderMapper'
import { CampaignRamadhanApiRepository } from '@/app/infrastructures/repositories/api/CampaignRamadhanApiRepository'
import { ExportDataApiRepository } from '@/app/infrastructures/repositories/api/ExportDataApiRepository'
import { DriverScoreboardApiRepository } from '@/app/infrastructures/repositories/api/DriverScoreboardApiRepository'
import { DriverScoreboardMapper } from '@/data/persistences/mappers/DriverScoreboardMapper'
import { PODTrackerApiRepository } from '@/app/infrastructures/repositories/api/PODTrackerApiRepository'
import { PODTrackerMapper } from '@/data/persistences/mappers/PODTrackerMapper'
import { SelectedMerchantApiRepository } from '@/app/infrastructures/repositories/api/SelectedMerchantRepository'
import { SelectedMerchantMapper } from '@/data/persistences/mappers/SelectedMerchantMapper'
import { CategorySectionApiRepository } from '@/app/infrastructures/repositories/api/CategorySectionApiRepository'
import { CategorySectionMapper } from '@/data/persistences/mappers/CategorySectionMapper'
import { CODTrackerApiRepository } from '@/app/infrastructures/repositories/api/CODTrackerApiRepository'
import { CODTrackerMapper } from '@/data/persistences/mappers/CODTrackerMapper'
import { WithdrawalApiRepository } from '@/app/infrastructures/repositories/api/WithdrawalApiRepository'
import { WithdrawalMapper } from '@/data/persistences/mappers/WithdrawalMapper'
import { PickupApiRepository } from '@/app/infrastructures/repositories/api/PickupApiRepository'
import { PickupMapper } from '@/data/persistences/mappers/PickupMapper'
import { HomepageBannerApiRepository } from '@/app/infrastructures/repositories/api/HomepageBannerApiRepository'
import { HomepageBannerMapper } from '@/data/persistences/mappers/HomepageBannerMapper'
import { DriverAnnouncementApiRepository } from '@/app/infrastructures/repositories/api/DriverAnnouncementApiRepository'
import { DriverAnnouncementMapper } from '@/data/persistences/mappers/DriverAnnouncementMapper'
import { ManageCourierApiRepository } from '@/app/infrastructures/repositories/api/ManageCourierApiRepository'
import { ManageCourierMapper } from '@/data/persistences/mappers/ManageCourierMapper'
import { ManageCustomerApiRepository } from '@/app/infrastructures/repositories/api/ManageCustomerApiRepository'
import { ManageCustomerMapper } from '@/data/persistences/mappers/ManageCustomerMapper'
import { MixPanelApiRepository } from '@/app/infrastructures/repositories/api/MixPanelRepository'
import { ManualPODPhotosApiRepository } from '@/app/infrastructures/repositories/api/ManualPODPhotosApiRepository'
import { ManualPODPhotosMapper } from '@/data/persistences/mappers/ManualPODPhotosMapper'
import { WhiteSellerApiRepository } from '@/app/infrastructures/repositories/api/WhiteSellerApiRepository'
import { WhiteSellerMapper } from '@/data/persistences/mappers/WhiteSellerMapper'
import { PayrollApiRepository } from '@/app/infrastructures/repositories/api/PayrollApiRepository'
import { PayrollMapper } from '@/data/persistences/mappers/PayrollMapper'
import { ManualAdjustmentBalanceApiRepository } from '@/app/infrastructures/repositories/api/ManualAdjustmentBalanceApiRepository'
import { ManualdjustmentBalanceMapper } from '@/data/persistences/mappers/ManualAdjustmentMapperMapper'
import { ManageDayApiRepository } from '@/app/infrastructures/repositories/api/ManageDayApiRespository'
import { ManageDayMapper } from '@/data/persistences/mappers/ManageDayMapper'
import { PayrollBonusApiRepository } from '@/app/infrastructures/repositories/api/PayrollBonusApiRepository'
import { PayrollBonusMapper } from '@/data/persistences/mappers/PayrollBonusMapper'
import { RequestTopupSaldoApiRepository } from '@/app/infrastructures/repositories/api/RequestTopupSaldoApiRepository'
import { RequestTopupSaldoMapper } from '@/data/persistences/mappers/RequestTopupSaldoMapper'
import { ManageCaptainApiRepository } from '@/app/infrastructures/repositories/api/ManageCaptainApiRepository'
import { ManageCaptainMapper } from '@/data/persistences/mappers/ManageCaptainMapper'
import { ManageDataPayrollApiRepository } from "@/app/infrastructures/repositories/api/ManageDataPayrollApiRepository";
import { ManageDataPayrollMapper } from "@/data/persistences/mappers/ManageDataPayrollMapper";
import { PickupLocationApiRepository } from '@/app/infrastructures/repositories/api/PickupLocationApiRepository'
import { PickupLocationMapper } from '@/data/persistences/mappers/PickupLocationMapper'
import { ScheduleOrderApiRepository } from '@/app/infrastructures/repositories/api/ScheduleOrderApiRepository'
import { ScheduleOrderMapper } from '@/data/persistences/mappers/ScheduleOrderMapper'
import { ManageCourierDedicatedApiRepository } from '@/app/infrastructures/repositories/api/ManageCourierDedicatedApiRepository'
import { ManageCourierDedicatedMapper } from '@/data/persistences/mappers/ManageCourierDedicatedMapper'
import { LoyaltyProgramGetPointRulesMapper } from '@/data/persistences/mappers/LoyaltyProgramGetPointRulesMapper'
import { LoyaltyProgramGetPointRulesRepository } from '@/app/infrastructures/repositories/api/LoyaltyProgramGetPointRulesRepository'
import { MembershipFormulaApiRepository } from '@/app/infrastructures/repositories/api/MembershipFormulaApiRepository'
import { MembershipFormulaMapper } from '@/data/persistences/mappers/MembershipFormulaMapper'
import {
  LoyaltyProgramMembershipRepository
} from '@/app/infrastructures/repositories/api/LoyaltyProgramMembershipRepository'
import { LoyaltyProgramMembershipMapper } from '@/data/persistences/mappers/LoyaltyProgramMembershipMapper'
import { AdjustmentParcelPoinRepository } from '@/app/infrastructures/repositories/api/AdjustmentParcelPoinRepository'
import { AdjustmentParcelPoinMapper } from '@/data/persistences/mappers/AdjustmentParcelPoinMapper'
import { ReschedulePickupRepository } from '../repositories/api/ReschedulePickupRepository'
import { ReschedulePickupMapper } from '@/data/persistences/mappers/ReschedulePickupMapper'
import { ApprovalAdjustSaldoRepository } from '@/app/infrastructures/repositories/api/ApprovalAdjustSaldoRepository'
import { ApprovalAdjustSaldoMapper } from '@/data/persistences/mappers/ApprovalAdjustSaldoMapper'
import { PickupRadiusValidationApiRepository } from '@/app/infrastructures/repositories/api/PickupRadiusValidationApiRepository'
import { PickupRadiusValidationMapper } from '@/data/persistences/mappers/PickupRadiusValidationMapper'
import { UpdateAppVersionApiRepository } from '@/app/infrastructures/repositories/api/UpdateAppVersionApiRepository'
import { UpdateAppVersionMapper } from '@/data/persistences/mappers/UpdateAppVersionMapper'
import { PackageMapper } from '@/data/persistences/mappers/PackageMapper'
import { PackageApiRepository } from '@/app/infrastructures/repositories/api/PackageApiRepository'
import { PhoneCodeApiRepository } from '../repositories/api/PhoneCodeApiRepository'
import { PhoneCodeMapper } from '@/data/persistences/mappers/PhoneCodeMapper'
import { CancelValidationApiRepository } from '../repositories/api/CancelValidationApiRepository'
import { CancelValidationMapper } from '@/data/persistences/mappers/CancelValidationMapper'
import { PickupTimeManagementApiRepository } from '../repositories/api/PickupTimeManagementApiRepository'
import { PickupTimeManagementMapper } from '@/data/persistences/mappers/PickupTimeManagementMapper'
import { RewardAndPunishmentApiRepository } from '../repositories/api/RewardAndPunishmentApiRepository'
import { RewardAndPunishmentMapper } from '@/data/persistences/mappers/RewardAndPunishmentMapper'
import { ShuttleApiRepository } from '../repositories/api/ShuttleApiRepository'
import { ShuttleMapper } from '@/data/persistences/mappers/ShuttleMapper'
import { CODApiRepository } from '../repositories/api/CODApiRepository'
import { CODMapper } from '@/data/persistences/mappers/CODMapper'
import { VoucherConfigSKURepository } from '@/app/infrastructures/repositories/api/VoucherConfigSKURepository'
import { VoucherConfigSKUMapper } from '@/data/persistences/mappers/VoucherConfigSKUMapper'
import { VoucherConfigSetLimitRepository } from '@/app/infrastructures/repositories/api/VoucherConfigSetLimitRepository'
import { VoucherConfigSetLimitMapper } from '@/data/persistences/mappers/VoucherConfigSetLimitMapper'
import { VoucherConfigHistoryBulkyRepository } from '@/app/infrastructures/repositories/api/VoucherConfigHistoryBulkyRepository'
import { VoucherConfigHistoryBulkyMapper } from '@/data/persistences/mappers/VoucherConfigHistoryBulkyMapper'

export class RepositoryModule {
  public static init(container: DependencyContainer): void {
    container.register<AuthApiRepository>(AuthApiRepository, {
      useFactory: d => {
        return new AuthApiRepository(
          d.resolve(ApiService),
          d.resolve(AuthDataMapper),
        )
      },
    })
    container.register<ProgramaticPointApiRepository>(
      ProgramaticPointApiRepository,
      {
        useFactory: d => {
          return new ProgramaticPointApiRepository(
            d.resolve(ApiService),
            d.resolve(ProgramaticPointMapper),
          )
        },
      },
    )
    container.register<PointPurposeApiRepository>(PointPurposeApiRepository, {
      useFactory: d => {
        return new PointPurposeApiRepository(
          d.resolve(ApiService),
          d.resolve(PointPurposeMapper),
        )
      },
    })
    container.register<BannerApiRepository>(BannerApiRepository, {
      useFactory: d => {
        return new BannerApiRepository(
          d.resolve(ApiService),
          d.resolve(BannerMapper),
        )
      },
    })
    container.register<POSApiRepository>(POSApiRepository, {
      useFactory: d => {
        return new POSApiRepository(d.resolve(ApiService), d.resolve(POSMapper))
      },
    })
    container.register<CourierApiRepository>(CourierApiRepository, {
      useFactory: d => {
        return new CourierApiRepository(
          d.resolve(ApiService),
          d.resolve(CourierMapper),
        )
      },
    })
    container.register<SaldoPaymentApiRepository>(SaldoPaymentApiRepository, {
      useFactory: d => {
        return new SaldoPaymentApiRepository(
          d.resolve(ApiService),
          d.resolve(SaldoPaymentMapper),
        )
      },
    })
    container.register<UserManagementApiRepository>(
      UserManagementApiRepository,
      {
        useFactory: d => {
          return new UserManagementApiRepository(
            d.resolve(ApiService),
            d.resolve(UserManagementMapper),
          )
        },
      },
    )
    container.register<FAQApiRepository>(FAQApiRepository, {
      useFactory: d => {
        return new FAQApiRepository(d.resolve(ApiService), d.resolve(FAQMapper))
      },
    })
    container.register<PNApiRepository>(PNApiRepository, {
      useFactory: d => {
        return new PNApiRepository(d.resolve(ApiService), d.resolve(PNMapper))
      },
    })
    container.register<ClaimApiRepository>(ClaimApiRepository, {
      useFactory: d => {
        return new ClaimApiRepository(
          d.resolve(ApiService),
          d.resolve(ClaimMapper),
        )
      },
    })
    container.register<WithdrawalApiRepository>(WithdrawalApiRepository, {
      useFactory: d => {
        return new WithdrawalApiRepository(
          d.resolve(ApiService),
          d.resolve(WithdrawalMapper)
        )
      },
    })
    container.register<PublicVoucherApiRepository>(PublicVoucherApiRepository, {
      useFactory: d => {
        return new PublicVoucherApiRepository(
          d.resolve(ApiService),
          d.resolve(PublicVoucherMapper),
        )
      },
    })
    container.register<ProgrammaticVoucherApiRepository>(
      ProgrammaticVoucherApiRepository,
      {
        useFactory: d => {
          return new ProgrammaticVoucherApiRepository(
            d.resolve(ApiService),
            d.resolve(ProgrammaticVoucherMapper),
            d.resolve(ProgramaticPointMapper),
          )
        },
      },
    ),
      container.register<BrandApiRepository>(BrandApiRepository, {
        useFactory: d => {
          return new BrandApiRepository(
            d.resolve(ApiService),
            d.resolve(BrandMapper),
          )
        },
      }),
      container.register<MerchantApiRepository>(MerchantApiRepository, {
        useFactory: d => {
          return new MerchantApiRepository(
            d.resolve(ApiService),
            d.resolve(MerchantMapper),
          )
        },
      }),
      container.register<RouteApiRepository>(RouteApiRepository, {
        useFactory: d => {
          return new RouteApiRepository(
            d.resolve(ApiService),
            d.resolve(RouteMapper),
          )
        },
      }),
      container.register<ProductApiRepository>(ProductApiRepository, {
        useFactory: d => {
          return new ProductApiRepository(
            d.resolve(ApiService),
            d.resolve(ProductMapper),
          )
        },
      }),
      container.register<OrderApiRepository>(OrderApiRepository, {
        useFactory: d => {
          return new OrderApiRepository(
            d.resolve(ApiService),
            d.resolve(OrderMapper),
          )
        },
      })
    container.register<CampaignRamadhanApiRepository>(
      CampaignRamadhanApiRepository,
      {
        useFactory: d => {
          return new CampaignRamadhanApiRepository(d.resolve(ApiService))
        },
      },
    )
    container.register<ExportDataApiRepository>(ExportDataApiRepository, {
      useFactory: d => {
        return new ExportDataApiRepository(d.resolve(ApiService))
      },
    })
    container.register<DriverScoreboardApiRepository>(DriverScoreboardApiRepository, {
      useFactory: d => {
        return new DriverScoreboardApiRepository(
          d.resolve(ApiService),
          d.resolve(DriverScoreboardMapper),
        )
      },
    })
    container.register<PODTrackerApiRepository>(PODTrackerApiRepository, {
      useFactory: d => {
        return new PODTrackerApiRepository(
          d.resolve(ApiService),
          d.resolve(PODTrackerMapper),
        )
      },
    })
    container.register<ManualPODPhotosApiRepository>(ManualPODPhotosApiRepository, {
      useFactory: d => {
        return new ManualPODPhotosApiRepository(
          d.resolve(ApiService),
          d.resolve(ManualPODPhotosMapper),
        )
      },
    })
    container.register<SelectedMerchantApiRepository>(SelectedMerchantApiRepository, {
      useFactory: d => {
        return new SelectedMerchantApiRepository(
          d.resolve(ApiService),
          d.resolve(SelectedMerchantMapper),
        )
      },
    })
    container.register<CategorySectionApiRepository>(CategorySectionApiRepository, {
      useFactory: d => {
        return new CategorySectionApiRepository(
          d.resolve(ApiService),
          d.resolve(CategorySectionMapper),
        )
      },
    })
    container.register<CODTrackerApiRepository>(CODTrackerApiRepository, {
      useFactory: d => {
        return new CODTrackerApiRepository(
          d.resolve(ApiService),
          d.resolve(CODTrackerMapper),
        )
      },
    })
    container.register<PickupApiRepository>(PickupApiRepository, {
      useFactory: d => {
        return new PickupApiRepository(
          d.resolve(ApiService),
          d.resolve(PickupMapper),
        )
      },
    })
    container.register<HomepageBannerApiRepository>(HomepageBannerApiRepository, {
      useFactory: d => {
        return new HomepageBannerApiRepository(
          d.resolve(ApiService),
          d.resolve(HomepageBannerMapper),
        )
      },
    })
    container.register<DriverAnnouncementApiRepository>(DriverAnnouncementApiRepository, {
      useFactory: d => {
        return new DriverAnnouncementApiRepository(
          d.resolve(ApiService),
          d.resolve(DriverAnnouncementMapper),
        )
      },
    })
    container.register<ManageCourierApiRepository>(ManageCourierApiRepository, {
      useFactory: d => {
        return new ManageCourierApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageCourierMapper),
        )
      },
    })
    container.register<ManageCustomerApiRepository>(ManageCustomerApiRepository, {
      useFactory: d => {
        return new ManageCustomerApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageCustomerMapper),
        )
      },
    })
    container.register<MixPanelApiRepository>(MixPanelApiRepository, {
      useFactory: d => {
        return new MixPanelApiRepository(d.resolve(ApiService))
      },
    })
    container.register<WhiteSellerApiRepository>(WhiteSellerApiRepository, {
      useFactory: d => {
        return new WhiteSellerApiRepository(
          d.resolve(ApiService),
          d.resolve(WhiteSellerMapper)
        )
      },
    })
    container.register<PayrollApiRepository>(PayrollApiRepository, {
      useFactory: d => {
        return new PayrollApiRepository(
          d.resolve(ApiService),
          d.resolve(PayrollMapper)
        )
      },
    })
    container.register<PayrollBonusApiRepository>(PayrollBonusApiRepository, {
      useFactory: d => {
        return new PayrollBonusApiRepository(
          d.resolve(ApiService),
          d.resolve(PayrollBonusMapper)
        )
      }
    })
    container.register<ManualAdjustmentBalanceApiRepository>(ManualAdjustmentBalanceApiRepository, {
      useFactory: d => {
        return new ManualAdjustmentBalanceApiRepository(
          d.resolve(ApiService),
          d.resolve(ManualdjustmentBalanceMapper)
        )
      },
    })
    container.register<ManageDayApiRepository>(ManageDayApiRepository, {
      useFactory: d => {
        return new ManageDayApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageDayMapper)
        )
      },
    })
    container.register<RequestTopupSaldoApiRepository>(RequestTopupSaldoApiRepository, {
      useFactory: d => {
        return new RequestTopupSaldoApiRepository(
          d.resolve(ApiService),
          d.resolve(RequestTopupSaldoMapper)
        )
      },
    })
    container.register<ManageCaptainApiRepository>(ManageCaptainApiRepository, {
      useFactory: d => {
        return new ManageCaptainApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageCaptainMapper)
        )
      },
    })
    container.register<ManageDataPayrollApiRepository>(ManageDataPayrollApiRepository, {
      useFactory: d => {
        return new ManageDataPayrollApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageDataPayrollMapper)
        )
      },
    })
    container.register<PickupLocationApiRepository>(PickupLocationApiRepository, {
      useFactory: d => {
        return new PickupLocationApiRepository(
          d.resolve(ApiService),
          d.resolve(PickupLocationMapper)
        )
      }
    })
    container.register<ScheduleOrderApiRepository>(ScheduleOrderApiRepository, {
      useFactory: d => {
        return new ScheduleOrderApiRepository(
          d.resolve(ApiService),
          d.resolve(ScheduleOrderMapper)
        )
      },
    })
    container.register<ManageCourierDedicatedApiRepository>(ManageCourierDedicatedApiRepository, {
      useFactory: d => {
        return new ManageCourierDedicatedApiRepository(
          d.resolve(ApiService),
          d.resolve(ManageCourierDedicatedMapper)
        )
      },
    })
    container.register<LoyaltyProgramGetPointRulesRepository>(LoyaltyProgramGetPointRulesRepository, {
      useFactory: d => {
        return new LoyaltyProgramGetPointRulesRepository(
          d.resolve(ApiService),
          d.resolve(LoyaltyProgramGetPointRulesMapper)
        )
      }
    })
    container.register<MembershipFormulaApiRepository>(MembershipFormulaApiRepository, {
      useFactory: d => {
        return new MembershipFormulaApiRepository(
          d.resolve(ApiService),
          d.resolve(MembershipFormulaMapper)
        )
      },
    })
    container.register<LoyaltyProgramMembershipRepository>(LoyaltyProgramMembershipRepository, {
      useFactory: d => {
        return new LoyaltyProgramMembershipRepository(
          d.resolve(ApiService),
          d.resolve(LoyaltyProgramMembershipMapper)
        )
      },
    })
    container.register<AdjustmentParcelPoinRepository>(AdjustmentParcelPoinRepository, {
      useFactory: d => {
        return new AdjustmentParcelPoinRepository(
          d.resolve(ApiService),
          d.resolve(AdjustmentParcelPoinMapper)
        )
      }
    })
    container.register<ReschedulePickupRepository>(ReschedulePickupRepository, {
      useFactory: d => {
        return new ReschedulePickupRepository(
          d.resolve(ApiService),
          d.resolve(ReschedulePickupMapper)
        )
      }
    })
    container.register<ApprovalAdjustSaldoRepository>(ApprovalAdjustSaldoRepository, {
      useFactory: d => {
        return new ApprovalAdjustSaldoRepository(
          d.resolve(ApiService),
          d.resolve(ApprovalAdjustSaldoMapper)
        )
      }
    })
    container.register<PickupRadiusValidationApiRepository>(PickupRadiusValidationApiRepository, {
      useFactory: d => {
        return new PickupRadiusValidationApiRepository(
          d.resolve(ApiService),
          d.resolve(PickupRadiusValidationMapper)
        )
      }
    })
    container.register<UpdateAppVersionApiRepository>(UpdateAppVersionApiRepository, {
      useFactory: d => {
        return new UpdateAppVersionApiRepository(
          d.resolve(ApiService),
          d.resolve(UpdateAppVersionMapper)
        )
      }
    })
    container.register<PackageApiRepository>(PackageApiRepository, {
      useFactory: d => {
        return new PackageApiRepository(
          d.resolve(ApiService),
          d.resolve(PackageMapper)
        )
      }
    })
    container.register<PhoneCodeApiRepository>(PhoneCodeApiRepository, {
      useFactory: d => {
        return new PhoneCodeApiRepository(
          d.resolve(ApiService),
          d.resolve(PhoneCodeMapper)
        )
      }
    })
    container.register<CancelValidationApiRepository>(CancelValidationApiRepository, {
      useFactory: d => {
        return new CancelValidationApiRepository(
          d.resolve(ApiService),
          d.resolve(CancelValidationMapper)
        )
      }
    })
    container.register<PickupTimeManagementApiRepository>(PickupTimeManagementApiRepository, {
      useFactory: d => {
        return new PickupTimeManagementApiRepository(
          d.resolve(ApiService),
          d.resolve(PickupTimeManagementMapper)
        )
      }
    })
    container.register<RewardAndPunishmentApiRepository>(RewardAndPunishmentApiRepository, {
      useFactory: d => {
        return new RewardAndPunishmentApiRepository(
          d.resolve(ApiService),
          d.resolve(RewardAndPunishmentMapper)
        )
      }
    })
    container.register<ShuttleApiRepository>(ShuttleApiRepository, {
      useFactory: d => {
        return new ShuttleApiRepository(
          d.resolve(ApiService),
          d.resolve(ShuttleMapper)
        )
      }
    })
    container.register<CODApiRepository>(CODApiRepository, {
      useFactory: d => {
        return new CODApiRepository(
          d.resolve(ApiService),
          d.resolve(CODMapper)
        )
      }
    })
    container.register<VoucherConfigSKURepository>(VoucherConfigSKURepository, {
      useFactory: d => {
        return new VoucherConfigSKURepository(
          d.resolve(ApiService),
          d.resolve(VoucherConfigSKUMapper)
        )
      }
    })
    container.register<VoucherConfigSetLimitRepository>(VoucherConfigSetLimitRepository, {
      useFactory: d => {
        return new VoucherConfigSetLimitRepository(
          d.resolve(ApiService),
          d.resolve(VoucherConfigSetLimitMapper)
        )
      }
    })
    container.register<VoucherConfigHistoryBulkyRepository>(VoucherConfigHistoryBulkyRepository, {
      useFactory: d => {
        return new VoucherConfigHistoryBulkyRepository(
          d.resolve(ApiService),
          d.resolve(VoucherConfigHistoryBulkyMapper)
        )
      }
    })
  }
}

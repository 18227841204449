import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'

export const rewardAndPunishment: RouteConfig = {
  path: '/r&p',
  component: MainLayout,
  meta: {
    title: 'Bonus & Penalti',
    icon: 'MenuIconRewardAndPunishment',
  },
  redirect: {
    name: 'RewardAndPunishment',
  },
  children: [
    {
      path: 'program',
      name: 'RewardAndPunishment',
      component: {
        render: c => c('router-view'),
      },
      redirect: {
        name: 'RewardAndPunishmentProgram'
      },
      children: [
        {
          path: '',
          name: 'RewardAndPunishmentProgram',
          component: () =>
            import(
              /* webpackChunkName: "RewardAndPunishmentProgram" */ '@/app/ui/views/RewardAndPunishment/Program/index.vue'
            ),
          meta: {
            title: 'Bonus & Penalti',
            isParent: false,
            slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
          },
        },
        {
          path: 'detail/:programId',
          name: 'RewardAndPunishmentProgramDetail',
          component: () =>
            import(
              /* webpackChunkName: "RewardAndPunishmentProgramDetail" */ '@/app/ui/views/RewardAndPunishment/Program/Detail/index.vue'
            ),
          meta: {
            isParent: true,
            title: 'Daftar Bonus & Penalti',
            slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
          },
        },
        {
          path: 'add-r&p/:programId',
          name: 'RewardAndPunishmentAdd',
          component: () =>
            import(
              /* webpackChunkName: "RewardAndPunishmentAdd" */ '@/app/ui/views/RewardAndPunishment/Program/Detail/SetupLevel/index.vue'
            ),
          meta: {
            isParent: true,
            title: 'Tambah Bonus & Penalti',
            slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
          },
        },
        {
          path: 'edit-r&p/:programId/:levelId',
          name: 'RewardAndPunishmentEdit',
          component: () =>
            import(
              /* webpackChunkName: "RewardAndPunishmentEdit" */ '@/app/ui/views/RewardAndPunishment/Program/Detail/SetupLevel/index.vue'
            ),
          meta: {
            isParent: true,
            title: 'Edit Bonus & Penalti',
            slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
          },
        },
        {
          path: 'detail-r&p/:programId/:levelId',
          name: 'RewardAndPunishmentDetail',
          component: () =>
            import(
              /* webpackChunkName: "RewardAndPunishmentDetail" */ '@/app/ui/views/RewardAndPunishment/Program/Detail/SetupLevel/index.vue'
            ),
          meta: {
            isParent: true,
            title: 'Detail Bonus & Penalti',
            slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
          },
        },
      ],
      meta: {
        title: 'Atur Bonus & Penalti',
        isParent: false,
        slugFromBackend: BACKEND_SLUG.REWARD_AND_PUNISHMENT,
      },
    },
  ],
}

import { Pagination } from './Pagination'

export class RequestTopupSaldoData {
  public customerId?: number
  public balanceId?: number
  public balanceTypeId?: string
  public balanceStatusId?: string
  public balanceAmount?: number
  public phoneNumber?: string
  public createdAt?: string

  constructor(
    customerId?: number,
    balanceId?: number,
    balanceTypeId?: string,
    balanceStatusId?: string,
    balanceAmount?: number,
    phoneNumber?: string,
    createdAt?: string
  ) {
    this.customerId = customerId
    this.balanceId = balanceId
    this.balanceTypeId = balanceTypeId
    this.balanceStatusId = balanceStatusId
    this.balanceAmount = balanceAmount
    this.phoneNumber = phoneNumber
    this.createdAt = createdAt
  }
}

export class RequestTopupSaldoList {
  public pagination?: Pagination
  public data?: RequestTopupSaldoData[]
  public phoneNumber?: string

  constructor(
    pagination?: Pagination,
    data?: RequestTopupSaldoData[],
    phoneNumber?: string
  ) {
    this.pagination = pagination
    this.data = data
    this.phoneNumber = phoneNumber
  }
}

export class RequestTopupSaldoHistory {
  public approval?: string
  public balanceId?: number
  public balanceStatusId?: string
  public customerId?: number
  public phoneNumber?: string
  public totalSaldoRequest?: number
  public currentBalance?: number
  public requestor?: string
  public note?: string
  public createdAt?: string
  public balanceTypeID?: string

  constructor(
    approval?: string,
    balanceId?: number,
    balanceStatusId?: string,
    customerId?: number,
    phoneNumber?: string,
    totalSaldoRequest?: number,
    currentBalance?: number,
    requestor?: string,
    note?: string,
    createdAt?: string,
    balanceTypeID?: string
  ) {
    this.approval = approval
    this.balanceId = balanceId
    this.balanceStatusId = balanceStatusId
    this.customerId = customerId
    this.phoneNumber = phoneNumber
    this.totalSaldoRequest = totalSaldoRequest
    this.currentBalance = currentBalance
    this.requestor = requestor
    this.note = note
    this.createdAt = createdAt
    this.balanceTypeID = balanceTypeID
  }
}

export class RequestTopupSaldoHistoryList {
  public pagination?: Pagination
  public data?: RequestTopupSaldoHistory[]
  public phoneNumber?: string

  constructor(
    pagination?: Pagination,
    data?: RequestTopupSaldoHistory[],
    phoneNumber?: string
  ) {
    this.pagination = pagination
    this.data = data
    this.phoneNumber = phoneNumber
  }
}

export class DetailTopupSaldo {
  public balanceId?: number
  public customerId?: number
  public amountRequested?: number
  public note?: string
  public actor?: string
  public balanceTypeId?: string
  public balanceStatusId?: string
  public createdAt?: string
  public historyCreadtedAt?: string

  constructor(
    balanceId?: number,
    customerId?: number,
    amountRequested?: number,
    note?: string,
    actor?: string,
    balanceTypeId?: string,
    balanceStatusId?: string,
    createdAt?: string,
    historyCreatedAt?: string,
  ) {
    this.balanceId = balanceId
    this.customerId = customerId
    this.amountRequested = amountRequested
    this.note = note
    this.actor = actor
    this.balanceTypeId = balanceTypeId
    this.balanceStatusId = balanceStatusId
    this.createdAt = createdAt
    this.historyCreadtedAt = historyCreatedAt
  }
}

export class ResultUploadBulkyRequestTopUp {
  public fileName: string
  public totalRowValid: number
  public totalRowInvalid: number
  public totalValidAmountNegative: number
  public totalValidAmountPositive: number
  public totalInvalidAmountNegative: number
  public totalInvalidAmountPositive: number

  constructor(
    fileName: string,
    totalRowValid: number,
    totalRowInvalid: number,
    totalValidAmountNegative: number,
    totalValidAmountPositive: number,
    totalInvalidAmountNegative: number,
    totalInvalidAmountPositive: number
  ) {
    this.fileName = fileName
    this.totalRowValid = totalRowValid
    this.totalRowInvalid = totalRowInvalid
    this.totalValidAmountNegative = totalValidAmountNegative
    this.totalValidAmountPositive = totalValidAmountPositive
    this.totalInvalidAmountNegative = totalInvalidAmountNegative
    this.totalInvalidAmountPositive = totalInvalidAmountPositive
  }
}

export class DetailUploadBulkyRequestTopUp {
  public customerID: number
  public totalSaldo: number
  public note: string
  public isValid: boolean
  public reasonInvalid?: string
  public rowNumber?: number

  constructor(
    customerID: number,
    totalSaldo: number,
    note: string,
    isValid: boolean,
    reasonInvalid?: string,
    rowNumber?: number
  ) {
    this.customerID = customerID
    this.totalSaldo = totalSaldo
    this.note = note
    this.isValid = isValid
    this.reasonInvalid = reasonInvalid
    this.rowNumber = rowNumber
  }
}

export class DetailTopupSaldoForm {
  public data?: DetailTopupSaldo

  constructor(data?: DetailTopupSaldo) {
    this.data = data
  }
}

export class UploadBulky {
  public result: ResultUploadBulkyRequestTopUp
  public data: DetailUploadBulkyRequestTopUp[]
  public pagination: Pagination

  constructor(
    result: ResultUploadBulkyRequestTopUp,
    data: DetailUploadBulkyRequestTopUp[],
    pagination: Pagination
  ) {
    this.result = result
    this.data = data
    this.pagination = pagination
  }
}

export class dataHistoryUploadBulky {
  public id: string
  public filename: string
  public actor: string
  public failedCustomerIDs: Array<number>
  public createdAt: string

  constructor(
    id: string,
    filename: string,
    actor: string,
    failedCustomerIDs: Array<number>,
    createdAt: string
  ) {
    this.id = id
    this.filename = filename
    this.actor = actor
    this.failedCustomerIDs = failedCustomerIDs
    this.createdAt = createdAt
  }
}

export class HistoryUploadBulky {
  public data: Array<dataHistoryUploadBulky>
  public pagination: Pagination

  constructor(data: Array<dataHistoryUploadBulky>, pagination: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

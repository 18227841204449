import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import {
  ProgrammaticVoucher,
  ProgrammaticVouchers,
  ProgrammaticVoucherHistory,
  ProgrammaticVoucherHistories,
  VoucherDistribution,
  VoucherDistributions,
} from '@/domain/entities/ProgrammaticVoucher'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { ProgramaticVoucherDetail } from '@/data/responses/contracts/ProgramaticVoucherResponse';

export class ProgrammaticVoucherMapper extends BaseResponseMapper {
  public convertProgrammaticVouchersFromApi(
    result: AxiosResponse
  ): ProgrammaticVouchers {
    const {
      data: { pagination, data },
    } = result
    return new ProgrammaticVouchers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (vc: ProgramaticVoucherDetail) =>
          new ProgrammaticVoucher(
            vc.programmatic_voucher_id,
            vc.programmatic_voucher_name,
            vc.programmatic_voucher_target,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            vc.programmatic_voucher_trigger,
            vc.trigger_data.scheduled_at,
            vc.trigger_data.membership_level_up,
            undefined,
            undefined,
            undefined,
            vc.programmatic_voucher_purpose,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            vc.start_date,
            vc.end_date,
            vc.created_at,
            vc.updated_at,
            vc.created_by,
            vc.updated_by,
            vc.deleted_at,
            vc.status,
            vc.budget_amount,
            vc.budget_used,
          )
      )
    )
  }

  public convertProgrammaticVoucherFromApi(
    result: AxiosResponse
  ): ProgrammaticVoucher {
    const { data } = result
    return new ProgrammaticVoucher(
      data.programmatic_voucher_id,
      data.programmatic_voucher_name,
      data.programmatic_voucher_target,
      data.target_data.selected_user,
      data.target_data.selected_user_file_url,
      data.target_data.selected_user_file_name,
      data.target_data.os,
      data.target_data.version_validation,
      data.target_data.version_value,
      data.target_data.route_id &&
        data.target_data.route_id.map((item: number) => `${item}`),
      data.target_data.membership_level,
      data.programmatic_voucher_trigger,
      data.trigger_data.scheduled_at,
      data.trigger_data.membership_level_up,
      data.trigger_data.limit_per_day,
      data.trigger_data.invoice_type,
      data.programmatic_voucher_purpose_parent,
      data.programmatic_voucher_purpose,
      data.voucher_purpose_data.point_amount,
      data.voucher_purpose_data.point_expiry_day,
      data.voucher_purpose_data.point_purpose || '',
      data.voucher_purpose_data.discount_amount,
      data.voucher_purpose_data.discount_amount_minimum_transaction,
      data.voucher_purpose_data.discount_percentage,
      data.voucher_purpose_data.discount_percentage_minimum_transaction,
      data.voucher_purpose_data.discount_percentage_maximum_amount,
      data.voucher_purpose_data.cashback_percentage,
      data.voucher_purpose_data.cashback_percentage_minimum_transaction,
      data.voucher_purpose_data.cashback_percentage_maximum_point_amount,
      data.voucher_purpose_data.invoice_type,
      data.voucher_expiry_day,
      data.voucher_limit_usage,
      data.start_date,
      data.end_date,
      data.created_at,
      data.updated_at,
      data.created_by,
      data.updated_by,
      data.deleted_at,
      data.status,
      data.budget_amount,
      undefined,
      data.voucher_purpose_data.product_type,
      data.voucher_expiry_date
    )
  }

  public convertUploadedUserFileFromApi(
    result: AxiosResponse
  ): Record<string, unknown> {
    const { data } = result
    return Object.assign(
      {},
      {
        fileId: data.file_id,
        fileName: data.file_name,
        selectedUser: data.selected_user,
        url: data.url,
      }
    )
  }

  public convertProgrammaticVoucherHistoriesFromApi(
    result: AxiosResponse
  ): ProgrammaticVoucherHistories {
    const {
      data: { pagination, data },
    } = result
    return new ProgrammaticVoucherHistories(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (vc: Record<string, never>) =>
          new ProgrammaticVoucherHistory(
            vc.programmatic_voucher_item_id,
            vc.programmatic_voucher_name,
            vc.user_type,
            vc.user_type_detail,
            vc.customer_id,
            vc.customer_name,
            vc.customer_email,
            vc.city,
            vc.voucher_purpose,
            vc.point_initial_amount,
            vc.voucher_created_at,
            vc.voucher_used_at,
            vc.point_spent_amount,
            vc.voucher_percentage,
            vc.voucher_amount,
            vc.voucher_used,
            vc.limit_usage,
            vc.voucher_id,
            vc.voucher_maximum_amount,
          )
      )
    )
  }

  public convertVoucherDistributionsFromApi(
    result: AxiosResponse
  ): VoucherDistributions {
    const {
      data: { pagination, data },
    } = result
    return new VoucherDistributions(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (vc: Record<string, never>) =>
          new VoucherDistribution(
            vc.programmatic_voucher_id,
            vc.programmatic_voucher_item_id,
            vc.programmatic_voucher_name,
            vc.customer_id,
            vc.customer_name,
            vc.customer_email,
            vc.city,
            vc.voucher_id,
            vc.voucher_purpose,
            vc.voucher_amount,
            vc.voucher_percentage,
            vc.total_usage,
            vc.limit_usage
          )
      )
    )
  }
}

import {
  CreateProgramLevelBonusRewardAndPunishmentRequest,
  CreateProgramRewardAndPunishmentRequest,
  MoveProgramLevelRequest,
  UpdateProgramRewardAndPunishmentRequest,
} from '@/data/payload/api/RewardAndPunishmentRequest'
import { RewardAndPunishmentMapper } from '@/data/persistences/mappers/RewardAndPunishmentMapper'
import { RewardAndPunishmentRepositoryInterface } from '@/data/persistences/repositories/contracts/RewardAndPunishmentRepositoryInterface'
import {
  ProgramDetail,
  ProgramLevelDetail,
  Programs,
} from '@/domain/entities/RewardAndPunishment'
import { Endpoints } from '../../misc'
import ApiService from '../../services/ApiService'

export class RewardAndPunishmentApiRepository
  implements RewardAndPunishmentRepositoryInterface {
  private service: ApiService
  private mapper: RewardAndPunishmentMapper

  constructor(service: ApiService, mapper: RewardAndPunishmentMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getAllProgram(params: Map<string, string>): Promise<Programs> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs`,
      params
    )

    return this.mapper.convertProgramsFromApi(resp)
  }

  async createProgram(
    payload: CreateProgramRewardAndPunishmentRequest
  ): Promise<number> {
    const resp = await this.service.invoke(
      'POST',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs`,
      undefined,
      payload
    )

    return this.mapper.convertIdProgramsFromApi(resp)
  }

  async updateProgram(
    payload: UpdateProgramRewardAndPunishmentRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${payload.id}`,
      undefined,
      payload
    )

    return true
  }

  async deleteProgram(id: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${id}`
    )

    return true
  }

  async getProgramDetail(
    id: number,
    params: Map<string, string | number>
  ): Promise<ProgramDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${id}`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertProgramDetailFromApi(resp)
  }

  async moveLevel(payload: MoveProgramLevelRequest): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/move-level`,
      undefined,
      payload
    )

    return true
  }

  async deleteLevel(programId: number, levelId: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${programId}/level/${levelId}`
    )

    return true
  }

  async getLevel(
    programId: number,
    levelId: number
  ): Promise<ProgramLevelDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${programId}/level/${levelId}`
    )

    return this.mapper.convertProgramLevelDetailFromApi(resp)
  }

  async createLevel(
    programId: number,
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${programId}/level`,
      undefined,
      payload
    )

    return true
  }

  async updateLevel(
    programId: number,
    levelId: number,
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.REWARD_AND_PUNISHMENT}/programs/${programId}/level/${levelId}`,
      undefined,
      payload
    )

    return true
  }
}

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from './constants'
import { loyaltyProgram } from '@/app/ui/router/path/loyaltyProgram'
import { bisaBelanja } from '@/app/ui/router/path/bisaBelanja'
import { adjustmentParcelPoin } from './path/adjustmentParcelPoint'
import { voucher } from '@/app/ui/router/path/voucher'
import { saldo } from '@/app/ui/router/path/saldo'
import { pickup } from './path/pickup'
import { adjustmentSaldo } from '@/app/ui/router/path/adjustmentSaldo'
import { adjustSaldoApproval } from './path/adjustSaldoApproval'
import { claim } from './path/claim'
import { driver } from './path/driver'
import { updateAppVersion } from './path/updateAppVersion'
import { search } from './path/search'
import { manageCourier } from './path/manageCourier'
import { pickupTimeManagement } from './path/pickupTimeManagement'
import { rewardAndPunishment } from './path/rewardAndPunishment'
import { shuttleManagement } from './path/shuttleManagement'
import { cod } from './path/cod'
import { manualAdjustBasicFee } from './path/manualAdjustBasicFee'
import { adjustBasicFeeApproval } from './path/adjustBasicFeeApproval'

Vue.use(VueRouter)

/**
 * Knowledge Base:
 * ALL ROUTES THAT NEED TO BE SHOWN IN THE MENU MUST ADD `children`, otherwise it will be hidden from the menu TOTALLY.
 *
 * Icon for Menu:
 * Only can be added to parent route `meta` with property name `icon`.
 * The `icon` value naming is referenced to the imported component icon name in src/app/ui/layouts/Main/Sidebar/MenuItem/index.vue
 *
 * About Single Menu:
 *     - meta.single means the menu will shown on the sidebar as single item menu without a dropdown
 *     - first children will be acknowledged as the main routing of the single menu
 *     - therefore single menu, first children must have identical `path` as the parent property
 *
 * IF you want to hide some routes in the children, you can add `meta.isHidden` from them.
 *
 * Current User Management Logic(see ticket CA-781):
 *     - `meta.single` give the meaning it will only have one menu in sidebar without levels
 *     - `meta.isParent` requires `meta.single` this will flag the children as the main menu or as a parent page, when user access the main route of that group
 *     - `meta.slugFromBackend` and `meta.accessType` are logic flagger for show and hide menu from specific user
 *     - `meta.slugFromBackend` contains slug name that by contract defined from backend, related to available menu in the CMS
 *     - `meta.accessType` only have 2 types: 'write' and 'read'. This thing to prevent some role to have write access
 *     - `meta.allUserCanAccess` all user didn't need any permission granted to be able to access that page with this meta `true`
 */
export const routes: Array<RouteConfig> = [
  {
    path: '/',
  },
  {
    path: '/user-management',
    component: MainLayout,
    meta: {
      title: 'User Management',
      single: true,
      icon: 'MenuIconUserManagement',
    },
    children: [
      {
        path: '/user-management',
        name: 'UserManagementPage',
        component: () =>
          import(
            /* webpackChunkName: "UserManagementPage" */ '@/app/ui/views/UserManagement/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.USER_MANAGEMENT,
          accessType: 'read',
        },
      },
      {
        path: '/user-management/create',
        name: 'CreateUserManagementPage',
        component: () =>
          import(
            /* webpackChunkName: "CreateUserManagementPage" */ '@/app/ui/views/UserManagement/Create/index.vue'
          ),
        meta: {
          title: 'Create',
          slugFromBackend: BACKEND_SLUG.USER_MANAGEMENT,
          accessType: 'write',
        },
      },
      {
        path: '/user-management/:id',
        name: 'UserManagementDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "UserManagementDetailPage" */ '@/app/ui/views/UserManagement/Detail/index.vue'
          ),
        meta: {
          title: 'Detail',
          slugFromBackend: BACKEND_SLUG.USER_MANAGEMENT,
          accessType: 'read',
        },
      },
      {
        path: '/user-management/:id/edit',
        name: 'EditUserManagementPage',
        component: () =>
          import(
            /* webpackChunkName: "EditUserManagementPage" */ '@/app/ui/views/UserManagement/Edit/index.vue'
          ),
        meta: {
          title: 'Edit',
          slugFromBackend: BACKEND_SLUG.USER_MANAGEMENT,
          accessType: 'write',
        },
      },
    ],
  },
  saldo,
  adjustmentSaldo,
  adjustmentParcelPoin,
  voucher,
  {
    path: '/banner',
    component: MainLayout,
    redirect: 'banner/banner-in-app',
    meta: {
      title: 'Banner',
      single: false,
      icon: 'MenuIconBanner',
    },
    children: [
      {
        path: 'banner-in-app',
        name: 'BannerPage',
        component: () =>
          import(
            /* webpackChunkName: "BannerInAppPage" */ '@/app/ui/views/Banner/BannerInApp/index.vue'
          ),
        meta: {
          title: 'Banner in App',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.BANNER_IN_APP,
          accessType: 'read',
        },
      },
      {
        path: 'banner-in-app/create',
        name: 'CreateBannerPage',
        component: () =>
          import(
            /* webpackChunkName: "CreateBannerInAppPage" */ '@/app/ui/views/Banner/BannerInApp/CreateBanner/index.vue'
          ),
        meta: {
          title: 'Create New',
          isHidden: true,
          slugFromBackend: BACKEND_SLUG.BANNER_IN_APP,
          accessType: 'write',
        },
      },
      {
        path: 'banner-in-app/:id/edit',
        name: 'EditBannerPage',
        component: () =>
          import(
            /* webpackChunkName: "EditBannerInAppPage" */ '@/app/ui/views/Banner/BannerInApp/EditBanner/index.vue'
          ),
        meta: {
          title: 'Edit',
          isHidden: true,
          slugFromBackend: BACKEND_SLUG.BANNER_IN_APP,
          accessType: 'write',
        },
      },
      {
        path: 'banner-in-app/:id',
        name: 'BannerDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "BannerInAppDetailPage" */ '@/app/ui/views/Banner/BannerInApp/BannerDetail/index.vue'
          ),
        meta: {
          title: 'Banner Detail',
          isHidden: true,
          slugFromBackend: BACKEND_SLUG.BANNER_IN_APP,
          accessType: 'read',
        },
      },
      {
        path: 'pop-up-banner',
        name: 'PopUpBanner',
        component: () =>
          import(
            /* webpackChunkName: "PopUpBannerPage" */ '@/app/ui/views/Banner/PopUpBanner/index.vue'
          ),
        meta: {
          title: 'Pop Up Banner',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.POP_UP_BANNER,
          accessType: 'read',
        },
      },
      {
        path: 'pop-up-banner/create',
        name: 'PopUpBannerCreatePage',
        component: () =>
          import(
            /* webpackChunkName: "PopUpBannerCreatePage" */ '@/app/ui/views/Banner/PopUpBanner/Create/index.vue'
          ),
        meta: {
          title: 'Create New',
          isHidden: true,
          slugFromBackend: BACKEND_SLUG.POP_UP_BANNER,
          accessType: 'write',
        },
      },
      {
        path: 'pop-up-banner/:id/edit',
        name: 'PopUpBannerEditPage',
        component: () =>
          import(
            /* webpackChunkName: "PopUpBannerEditPage" */ '@/app/ui/views/Banner/PopUpBanner/Edit/index.vue'
          ),
        meta: {
          title: 'Edit',
          isHidden: true,
          slugFromBackend: BACKEND_SLUG.POP_UP_BANNER,
          accessType: 'write',
        },
      },
      {
        path: 'pop-up-banner/:id',
        name: 'PopUpBannerDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "PopUpBannerDetailPage" */ '@/app/ui/views/Banner/PopUpBanner/Detail/index.vue'
          ),
        meta: {
            title: 'Banner Pop Up Detail',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.POP_UP_BANNER,
            accessType: 'read',
        },
      },
    ],
  },
  {...loyaltyProgram},
  {
    path: '/pos',
    component: MainLayout,
    meta: {
      title: 'Mitra POS',
      single: true,
      icon: 'MenuIconMitraPOS',
    },
    children: [
      {
        path: '/pos',
        name: 'POSListPage',
        component: () =>
          import(
            /* webpackChunkName: "POSListPage" */ '@/app/ui/views/POS/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.MITRA_POS,
          accessType: 'read',
        },
      },
      {
        path: '/pos/:id',
        name: 'POSDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "POSDetailPage" */ '@/app/ui/views/POS/POSDetail/index.vue'
          ),
        meta: {
          title: 'Detail',
          slugFromBackend: BACKEND_SLUG.MITRA_POS,
          accessType: 'read',
        },
      },
    ],
  },
  {
    path: '/faq',
    component: MainLayout,
    meta: {
      title: 'FAQ',
      single: true,
      icon: 'MenuIconFAQ',
    },
    children: [
      {
        path: '/faq',
        name: 'FAQListPage',
        component: () =>
          import(
            /* webpackChunkName: "FAQListPage" */ '@/app/ui/views/FAQ/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.FAQ,
          accessType: 'read',
        },
      },
      {
        path: '/faq/create',
        name: 'CreateFAQPage',
        component: () =>
          import(
            /* webpackChunkName: "CreateFAQPage" */ '@/app/ui/views/FAQ/Create/index.vue'
          ),
        meta: {
          title: 'Create New',
          slugFromBackend: BACKEND_SLUG.FAQ,
          accessType: 'write',
        },
      },
      {
        path: '/faq/starred',
        name: 'FAQStarredPage',
        component: () =>
          import(
            /* webpackChunkName: "FAQStarredPage" */ '@/app/ui/views/FAQ/Starred/index.vue'
          ),
        meta: {
          title: 'Starred Question',
          slugFromBackend: BACKEND_SLUG.FAQ,
          accessType: 'read',
        }
      },
      {
        path: '/faq/:id/edit',
        name: 'EditFAQPage',
        component: () =>
          import(
            /* webpackChunkName: "EditFAQPage" */ '@/app/ui/views/FAQ/Edit/index.vue'
          ),
        meta: {
          title: 'Edit FAQ',
          slugFromBackend: BACKEND_SLUG.FAQ,
          accessType: 'read',
        },
      },
      {
        path: '/faq/:id',
        name: 'FAQDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "FAQDetailPage" */ '@/app/ui/views/FAQ/Detail/index.vue'
          ),
        meta: {
          title: 'FAQ Detail',
          slugFromBackend: BACKEND_SLUG.FAQ,
          accessType: 'read',
        },
      },
    ],
  },
  {
    path: '/push-notification',
    component: MainLayout,
    meta: {
      title: 'Push Notification',
      single: true,
      icon: 'MenuIconPN',
    },
    children: [
      {
        path: '/push-notification',
        name: 'PNListPage',
        component: () =>
          import(
            /* webpackChunkName: "PNListPage" */ '@/app/ui/views/PushNotification/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.PUSH_NOTIFICATION,
          accessType: 'read',
        },
      },
      {
        path: '/push-notification/create',
        name: 'CreatePNPage',
        component: () =>
          import(
            /* webpackChunkName: "CreatePNPage" */ '@/app/ui/views/PushNotification/Create/index.vue'
          ),
        meta: {
          title: 'Create New',
          slugFromBackend: BACKEND_SLUG.PUSH_NOTIFICATION,
          accessType: 'write',
        },
      },
      {
        path: '/push-notification/:id/edit',
        name: 'EditPNPage',
        component: () =>
          import(
            /* webpackChunkName: "EditPNPage" */ '@/app/ui/views/PushNotification/Edit/index.vue'
          ),
        meta: {
          title: 'Edit',
          slugFromBackend: BACKEND_SLUG.PUSH_NOTIFICATION,
          accessType: 'write',
        },
      },
      {
        path: '/push-notification/:id',
        name: 'DetailPNPage',
        component: () =>
          import(
            /* webpackChunkName: "DetailPNPage" */ '@/app/ui/views/PushNotification/Detail/index.vue'
          ),
        meta: {
          title: 'Detail',
          slugFromBackend: BACKEND_SLUG.PUSH_NOTIFICATION,
          accessType: 'read',
        },
      },
    ],
  },
  claim,
  {
    path: '/withdrawal',
    component: MainLayout,
    meta: {
      title: 'Withdrawal',
      single: true,
      icon: 'MenuIconWithdrawal',
    },
    children: [
      {
        path: '/withdrawal',
        name: 'WithdrawalPage',
        component: () =>
          import(
            /* webpackChunkName: "WithdrawalPage" */ '@/app/ui/views/Withdrawal/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.WITHDRAWAL,
          accessType: 'read',
        },
      },
      {
        path: '/withdrawal/:id',
        name: 'WithdrawalDetailPage',
        component: () =>
          import(
            /* webpackChunkName: "WithdrawalDetailPage" */ '@/app/ui/views/Withdrawal/Detail/index.vue'
          ),
        meta: {
          title: 'Detail',
          slugFromBackend: BACKEND_SLUG.WITHDRAWAL,
          accessType: 'read',
        },
      },
    ],
  },
  bisaBelanja,
  driver,
  rewardAndPunishment,
  cod, 
  pickupTimeManagement,
  shuttleManagement,
  updateAppVersion,
  {
    path: '/payroll',
    component: MainLayout,
    redirect: { name: 'ManageDataMaster' },
    meta: {
      title: 'Penghasilan',
      single: false,
      icon: 'MenuIconPayroll',
    },
    children: [
      {
        path: 'manage-data-master',
        name: 'ManageDataMaster',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageDataMasterList' },
        meta: {
          title: 'Manage Data Master',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageDataMasterList',
            component: () =>
              import(
                /* webpackChunkName: "ManageDataMaster" */ '@/app/ui/views/Payroll/ManageDataMaster/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: ':periodId/:page',
            name: 'ManageDataMasterDetail',
            redirect: '/payroll/manage-data-master/:periodId/:page/basic-fee',
            component: () =>
              import(
                /* webpackChunkName: "ManageDataMasterDetail" */ '@/app/ui/views/Payroll/ManageDataMaster/Detail/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
            children : [
              {
                path: 'basic-fee',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'BasicFeeDetail',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterDetailBasicFee" */ '@/app/ui/views/Payroll/ManageDataMaster/Detail/BasicFee/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'bonus-mechanism',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'BonusMechanismDetail',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterDetailBonusMechanism" */ '@/app/ui/views/Payroll/ManageDataMaster/Detail/BonusMechanism/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'funds-withdrawal',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'FundsWithdrawalDetail',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterDetailFundsWithdrawal" */ '@/app/ui/views/Payroll/ManageDataMaster/Detail/FundsWithdrawal/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'update-history',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'UpdateHistoryDetail',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterDetailUpdateHistory" */ '@/app/ui/views/Payroll/ManageDataMaster/Detail/UpdateHistory/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
            ]
          },
          {
            path: 'edit/:periodId/:page',
            name: 'ManageDataMasterEdit',
            redirect: '/payroll/manage-data-master/edit/:periodId/:page/basic-fee',
            component: () =>
              import(
                /* webpackChunkName: "ManageDataMasterEdit" */ '@/app/ui/views/Payroll/ManageDataMaster/Edit/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
            children : [
              {
                path: 'basic-fee',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'BasicFeeEdit',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterEditBasicFee" */ '@/app/ui/views/Payroll/ManageDataMaster/Edit/BasicFee/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'bonus-mechanism',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'BonusMechanismEdit',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterEditBonusMechanism" */ '@/app/ui/views/Payroll/ManageDataMaster/Edit/BonusMechanism/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'funds-withdrawal',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'FundsWithdrawalEdit',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterEditFundsWithdrawal" */ '@/app/ui/views/Payroll/ManageDataMaster/Edit/FundsWithdrawal/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
              {
                path: 'update-history',
                component: {
                  render: c => c('router-view')
                },
                meta: {
                  isParent: true,
                  isHidden: true,
                  slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                  accessType: 'read'
                },
                children: [
                  {
                    path: '',
                    name: 'UpdateHistoryEdit',
                    component: () =>
                      import (
                        /* webpackChunkName: "ManageDataMasterEditUpdateHistory" */ '@/app/ui/views/Payroll/ManageDataMaster/Edit/UpdateHistory/index.vue'
                      ),
                    meta: {
                      slugFromBackend: BACKEND_SLUG.MANAGE_DATA_MASTER,
                      accessType: 'read',
                      isParent: true,
                      isHidden: true
                    },
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'manual-adjust-saldo',
        name: 'ManualAdjustSaldo',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManualAdjustSaldoList' },
        meta: {
          title: 'Manual Adjust Saldo',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManualAdjustSaldoList',
            component: () =>
              import(
                /* webpackChunkName: "ManualAdjustSaldo" */ '@/app/ui/views/Payroll/ManualAdjustSaldo/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'detail/:id',
            name: 'DetailManualAdjustSaldo',
            component: () =>
              import(
                /* webpackChunkName: "ManualAdjustSaldoDetail" */ '@/app/ui/views/Payroll/AdjustSaldoApproval/Detail/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'create',
            name: 'CreateManualAdjustSaldo',
            component: () =>
              import(
                /* webpackChunkName: "ManualAdjustSaldoCreate" */ '@/app/ui/views/Payroll/ManualAdjustSaldo/Create/index.vue'
              ),
            meta: {
              title: 'Tambah Data',
              slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'upload-bulky',
            name: 'UploadBulkyManualAdjustSaldo',
            component: () =>
              import(
                /* webpackChunkName: "ManualAdjustSaldoUploadBulky" */ '@/app/ui/views/Payroll/ManualAdjustSaldo/UploadBulky/index.vue'
              ),
            meta: {
              title: 'Upload Bulky',
              slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'upload-bulky/list',
            name: 'UploadBulkyManualAdjustSaldoList',
            component: () =>
              import(
                /* webpackChunkName: "ManualAdjustSaldoUploadBulkyList" */ '@/app/ui/views/Payroll/ManualAdjustSaldo/UploadBulky/index.vue'
              ),
            meta: {
              title: 'Upload Bulky',
              slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_SALDO,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
        ]
      },
      manualAdjustBasicFee,
      {
        path: 'bonus',
        name: 'Bonus',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'BonusList' },
        meta: {
          title: 'Bonus',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.BONUS,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'BonusList',
            component: () =>
              import(
                /* webpackChunkName: "Bonus" */ '@/app/ui/views/Payroll/Bonus/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.BONUS,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: ':id',
            name: 'BonusDetail',
            component: () =>
              import(
                /* webpackChunkName: "BonusDetail" */ '@/app/ui/views/Payroll/Bonus/Detail/index.vue'
              ),
            meta: {
              title: 'Detail Data',
              slugFromBackend: BACKEND_SLUG.BONUS,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
        ]
      },
      {
        path: 'manage-captain',
        name: 'ManageCaptain',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageCaptainList' },
        meta: {
          title: 'Manage Captain',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_CAPTAIN,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageCaptainList',
            component: () =>
              import(
                /* webpackChunkName: "ManageCaptain" */ '@/app/ui/views/Payroll/ManageCaptain/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_CAPTAIN,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'detail/:id',
            name: 'ManageCaptainDetail',
            component: () =>
              import(
                /* webpackChunkName: "ManageCaptainDetail" */ '@/app/ui/views/Payroll/ManageCaptain/Detail/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_CAPTAIN,
              title: 'Detail Data',
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'create',
            name: 'CreateManageCaptain',
            component: () =>
              import(
                /* webpackChunkName: "CreateManageCaptain" */ '@/app/ui/views/Payroll/ManageCaptain/Create/index.vue'
              ),
            meta: {
              title: 'Tambah Data',
              slugFromBackend: BACKEND_SLUG.MANAGE_CAPTAIN,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'edit/:id',
            name: 'EditManageCaptain',
            component: () =>
              import(
                /* webpackChunkName: "EditManageCaptain" */ '@/app/ui/views/Payroll/ManageCaptain/Edit/index.vue'
              ),
            meta: {
              title: 'Edit Data',
              slugFromBackend: BACKEND_SLUG.MANAGE_CAPTAIN,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
        ]
      },
      {
        path: 'manage-data-penghasilan',
        name: 'ManageDataPenghasilan',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageDataPenghasilanList' },
        meta: {
          title: 'Manage Data Penghasilan',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_DATA_PAYROLL,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageDataPenghasilanList',
            component: () =>
              import(
                /* webpackChunkName: "ManageDataPayrollList" */ '@/app/ui/views/Payroll/ManageDataPayroll/index.vue'
                ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DATA_PAYROLL,
              accessType: 'read',
              isParent: true,
              isHidden: true,
            },
          },
          {
            path: 'detail/:id',
            name: 'ManageDataPenghasilanDetail',
            component: () =>
              import(
                /* webpackChunkName: "ManageDataPayrollDetail" */ '@/app/ui/views/Payroll/ManageDataPayroll/Detail/index.vue'
                ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DATA_PAYROLL,
              title: 'Detail Data',
              accessType: 'read',
              isParent: true,
              isHidden: true,
            },
          },
        ],
      },
      adjustSaldoApproval,
      adjustBasicFeeApproval,
    ],
  },
  {
    path: '/day',
    component: MainLayout,
    redirect: { name: 'ManageDays' },
    meta: {
      title: 'Jumlah Hari',
      single: false,
      icon: 'MenuIconDay',
    },
    children: [
      {
        path: 'manage-days',
        name: 'ManageDays',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageDaysList' },
        meta: {
          title: 'Manage Jumlah Hari',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_DAYS,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageDaysList',
            component: () =>
              import(
                /* webpackChunkName: "ManageDays" */ '@/app/ui/views/Payroll/ManageDays/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_DAYS,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
        ]
      },
      {
        path: 'manage-courier-attendance',
        name: 'ManageCourierAttendance',
        component: {
          render: c => c('router-view')
        },
        redirect: {
          name: 'ManageCourierAttendanceList'
        },
        children: [
          {
            path: '',
            name: 'ManageCourierAttendanceList',
            component: () => 
              import(
                /* webpackChunkName: "ManageCourierAttendance" */ '@/app/ui/views/Day/ManageCourierAttendance/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_ATTENDANCE,
            }
          },
          {
            path: 'detail/:courierId',
            name: 'ManageCourierAttendanceDetail',
            component: {
              render: c => c('router-view')
            },
            redirect: {
              name: 'ManageCourierAttendanceDetailCourier'
            },
            children: [
              {
                path: '',
                name: 'ManageCourierAttendanceDetailCourier',
                component: () =>
                  import(
                    /* webpackChunkName: "ManageCourierAttendanceDetailCourier" */  '@/app/ui/views/Day/ManageCourierAttendance/Detail/index.vue'
                  ),
                meta: {
                  slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_ATTENDANCE,
                  accesType: 'read',
                }
              }, 
              {
                path: ':dateTime',
                name: 'ManageCourierAttendanceDetailAttendanceRequest',
                component: () =>
                  import(
                    /* webpackChunkName: "ManageCourierAttendanceDetailAttendanceRequest" */ '@/app/ui/views/Day/ManageCourierAttendance/Detail/AttedanceRequest/index.vue'
                  ),
                
                meta: {
                  title: 'Detail absensi',
                  slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_ATTENDANCE,
                }
              }
            ],
            meta: {
              title: 'Detail absensi',
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_ATTENDANCE,
              accessType: 'read'
            }
          },
        ],
        meta: {
          title: 'Manage Absensi Kurir',
          slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_ATTENDANCE,
          accessType: 'read',
          isParent: true,
        }
      }
    ]
  },
  {
    path: '/offer',
    component: MainLayout,
    redirect: { name: 'ScheduleOrderOverviewPage' },
    meta: {
      title: 'Offer',
      single: false,
      icon: 'MenuIconOffer',
    },
    children: [
      {
        path: 'schedule-order',
        name: 'ScheduleOrder',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ScheduleOrderOverviewPage' },
        meta: {
          title: 'Atur Jadwal Pesanan',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.SCHEDULE_ORDER,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ScheduleOrderOverviewPage',
            component: () =>
              import(
                /* webpackChunkName: "ScheduleOrderOverviewPage" */ '@/app/ui/views/Offer/ScheduleOrder/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.SCHEDULE_ORDER,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: ':id',
            name: 'ScheduleOrderEditPage',
            component: () =>
              import(
                /* webpackChunkName: "ScheduleOrderEditPage" */ '@/app/ui/views/Offer/ScheduleOrder/Edit/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.SCHEDULE_ORDER,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          }
        ]
      },
      {
        path: 'manage-courier-dedicated',
        name: 'ManageCourierDedicated',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageCourierDedicatedListPage' },
        meta: {
          title: 'Manage Kurir Dedicated',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageCourierDedicatedListPage',
            component: () =>
              import(
                /* webpackChunkName: "ManageCourierDedicatedListPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
            children: [
              {
                path: 'area-dedicated',
                name: 'AreaDedicatedListPage',
                component: () =>
                  import(
                    /* webpackChunkName: "AreaDedicatedListPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/AreaDedicated/index.vue'
                  ),
                meta: {
                  slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
                  accessType: 'read',
                  isParent: true,
                  isHidden: true
                },
              },
              {
                path: 'courier-dedicated',
                name: 'CourierDedicatedListPage',
                component: () =>
                  import(
                    /* webpackChunkName: "CourierDedicatedListPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/CourierDedicated/index.vue'
                  ),
                meta: {
                  slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
                  accessType: 'read',
                  isParent: true,
                  isHidden: true
                },
              },
            ]
          },
          {
            path: 'create',
            name: 'ManageCourierDedicatedCreatePage',
            component: () =>
              import(
                /* webpackChunkName: "ManageCourierDedicatedCreatePage" */ '@/app/ui/views/Offer/ManageCourierDedicated/Create/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
              title: 'Tambah Area',
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'area-dedicated/edit/:id',
            name: 'AreaDedicatedEditPage',
            component: () =>
              import(
                /* webpackChunkName: "AreaDedicatedEditPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/AreaDedicated/Edit/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
              title: 'Edit Area',
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'area-dedicated/:id',
            name: 'AreaDedicatedDetailPage',
            component: () =>
              import(
                /* webpackChunkName: "AreaDedicateDetailPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/AreaDedicated/Detail/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
              title: 'Detail Area',
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: 'courier-dedicated/:courier',
            name: 'CourierDedicatedDetailPage',
            component: () =>
              import(
                /* webpackChunkName: "CourierDedicatedDetailPage" */ '@/app/ui/views/Offer/ManageCourierDedicated/CourierDedicated/Detail/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_COURIER_DEDICATED,
              title: 'Detail Dedicated Courier',
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
        ]
      },
    ],
  },
  {
    path: '/marketplace',
    component: MainLayout,
    redirect: { name: 'ManageWhitelist' },
    meta: {
      title: 'Marketplace',
      single: false,
      icon: 'MenuIconMarketPlace',
    },
    children: [
      {
        path: 'manage-whitelist',
        name: 'ManageWhitePage',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageWhitelist' },
        meta: {
          title: 'Manage Whitelist',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_WHITELIST,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageWhitelist',
            component: () =>
              import(
                /* webpackChunkName: "ManageWhitelist" */ '@/app/ui/views/Marketplace/ManageWhitelist/index.vue'
              ),
            meta: {
              slugFromBackend: BACKEND_SLUG.MANAGE_WHITELIST,
              accessType: 'read',
              isParent: true,
              isHidden: true
            },
          },
          {
            path: '/marketplace/manage-whitelist/create',
            name: 'AddSellerWhitelist',
            component: () =>
              import(
                /* webpackChunkName: "CreateWhiteSeller" */ '@/app/ui/views/Marketplace/ManageWhitelist/Create/index.vue'
              ),
            meta: {
              title: 'Tambah Seller',
              slugFromBackend: BACKEND_SLUG.MANAGE_WHITELIST,
              isHidden: true,
              accessType: 'write',
            },
          },
          {
            path: '/marketplace/manage-whitelist/:id',
            name: 'DetailSellerWhitelist',
            component: () =>
              import(
                /* webpackChunkName: "DetailWhiteSeller" */ '@/app/ui/views/Marketplace/ManageWhitelist/Detail/index.vue'
              ),
            meta: {
              title: 'Detail Seller',
              slugFromBackend: BACKEND_SLUG.MANAGE_WHITELIST,
              isHidden: true,
              accessType: 'write',
            },
          },
          {
            path: '/marketplace/manage-whitelist/edit/:id',
            name: 'EditSellerWhitelist',
            component: () =>
              import(
                /* webpackChunkName: "EditWhiteSeller" */ '@/app/ui/views/Marketplace/ManageWhitelist/Edit/index.vue'
              ),
            meta: {
              title: 'Edit Seller',
              slugFromBackend: BACKEND_SLUG.MANAGE_WHITELIST,
              isHidden: true,
              accessType: 'write',
            },
          },
        ]
      },
    ],
  },
  manageCourier,
  {
    path: '/manage-customer',
    component: MainLayout,
    redirect: { name: 'ManageCustomerList' },
    meta: {
      title: 'Manage Customer',
      single: false,
      icon: 'MenuIconManageCustomer',
    },
    children: [
      {
        path: '',
        name: 'ManageCustomerPage',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'ManageCustomerList' },
        meta: {
          title: 'Manage Customer',
          hasSubChildren: true,
          slugFromBackend: BACKEND_SLUG.MANAGE_CUSTOMER,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'ManageCustomerList',
            component: () =>
              import(
                /* webpackChunkName: "ManageCustomerList" */ '@/app/ui/views/ManageCustomer/List/index.vue'
              ),
            meta: {
              isParent: true,
              slugFromBackend: BACKEND_SLUG.MANAGE_CUSTOMER,
              accessType: 'read',
              isHidden: true
            },
          },
          {
            path: '/manage-customer/:id',
            name: 'ManageCustomerDetail',
            component: () =>
              import(
                /* webpackChunkName: "ManageCustomerDetail" */ '@/app/ui/views/ManageCustomer/Detail/index.vue'
              ),
            meta: {
              title: 'Detail Customer',
              isParent: true,
              slugFromBackend: BACKEND_SLUG.MANAGE_CUSTOMER,
              accessType: 'read',
              isHidden: true
            },
          },
        ]
      }
    ]
  },
  pickup,
  {
    path: '/driver-announcement',
    component: MainLayout,
    meta: {
      single: true,
      title: 'Pengumuman Kurir',
      icon: 'MenuIconPN',
    },
    children: [
      {
        path: '',
        component: {
          render: c => c('router-view'),
        },
        meta: {
          title: 'Pengumuman Kurir',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.DRIVER_ANNOUNCEMENT,
          accessType: 'read',
        },
        children: [
          {
            path: '',
            name: 'DriverAnnouncementList',
            component: () =>
              import(
                /* webpackChunkName: "DriverAnnouncementList" */ '@/app/ui/views/DriverAnnouncement/index.vue'
              ),
            meta: {
              title: 'Pengumuman Kurir',
              isParent: true,
              slugFromBackend: BACKEND_SLUG.DRIVER_ANNOUNCEMENT,
              accessType: 'read',
            },
          },
          {
            path: 'create',
            name: 'CreateDriverAnnouncement',
            component: () =>
              import(
                /* webpackChunkName: "CreateDriverAnnouncement" */ '@/app/ui/views/DriverAnnouncement/Create/index.vue'
              ),
            meta: {
              title: 'Buat Pengumuman',
              isParent: true,
              slugFromBackend: BACKEND_SLUG.DRIVER_ANNOUNCEMENT,
              accessType: 'write',
            },
          },
          {
            path: 'edit/:id',
            name: 'EditDriverAnnouncement',
            component: () =>
              import(
                /* webpackChunkName: "EditDriverAnnouncement" */ '@/app/ui/views/DriverAnnouncement/Edit/index.vue'
              ),
            meta: {
              title: 'Edit Pengumuman',
              isParent: true,
              slugFromBackend: BACKEND_SLUG.DRIVER_ANNOUNCEMENT,
              accessType: 'write',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/export-data',
    component: MainLayout,
    meta: {
      title: 'Export Data',
      single: true,
      icon: 'MenuIconExport',
    },
    children: [
      {
        path: '/export-data',
        name: 'ExportDataPage',
        component: () =>
          import(
            /* webpackChunkName: "ExportDataPage" */ '@/app/ui/views/ExportData/index.vue'
          ),
        meta: {
          title: '',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.EXPORT_DATA,
          accessType: 'read',
        },
      },
    ],
  },
  {
    path: '/settings',
    component: MainLayout,
    meta: {
      hideFromSidebar: true,
      title: 'Settings',
    },
    children: [
      {
        path: 'account',
        name: 'AccountSettingPage',
        component: () =>
          import(
            /* webpackChunkName: "AccountSettingPage" */ '@/app/ui/views/Setting/Account/index.vue'
          ),
        meta: {
          title: 'Account Settings',
          slugFromBackend: BACKEND_SLUG.ACCOUNT_SETTING,
          accessType: 'write',
          allUserCanAccess: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'LoginPage',
    meta: {
      title: 'Login',
    },
    component: () =>
      import(
        /* webpackChunkName: "LoginPage" */ '@/app/ui/views/Login/index.vue'
      ),
  },
  {
    path: '/login/reset-password',
    name: 'ResetPassword',
    meta: {
      title: 'Reset Password',
    },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ '@/app/ui/views/Login/ResetPassword/index.vue'
      ),
  },
  {
    path: '/campaign-ramadhan',
    name: 'CampaignRamadhanLandingPage',
    component: () =>
      import(
        /* webpackChunkName: "CampaignRamadhanLandingPage" */ '@/app/ui/views/CampaignRamadhan/LandingPage/index.vue'
      ),
    meta: {
      title: 'Campaign Ramadhan Landing Page',
    },
  },
  {
    path: '/campaign-ramadhan/form-page',
    name: 'CampaignRamadhanFormPage',
    component: () =>
      import(
        /* webpackChunkName: "CampaignRamadhanFormPage" */ '@/app/ui/views/CampaignRamadhan/FormPage/index.vue'
      ),
    meta: {
      title: 'Campaign Ramadhan Form Page',
    },
  },
  {
    path: '/campaign-ramadhan/end-page',
    name: 'CampaignRamadhanEndPage',
    component: () =>
      import(
        /* webpackChunkName: "CampaignRamadhanFormPage" */ '@/app/ui/views/CampaignRamadhan/EndPage/index.vue'
      ),
    meta: {
      title: 'Campaign Ramadhan End Page',
    },
  },
  {
    path: '/pickup-location',
    component: MainLayout,
    redirect: '/pickup-location',
    meta: {
      title: 'Pick Up Location',
      single: true,
      icon: 'MenuIconPickupLocation',
    },
    children: [
      {
        path: '',
        name: 'PickupLocation',
        component: () =>
          import(
            /* webpackChunkName: "PickupLocationListPage" */ '@/app/ui/views/PickUpLocation/index.vue'
          ),
        meta: {
          title: 'Pick Up Location',
          isParent: true,
          slugFromBackend: BACKEND_SLUG.PICKUP_LOCATION,
        }
      },
      {
        path: '/pickup-location/:id',
        name: 'DetailLocation',
        component: () =>
          import(
            /* webpackChunkName: "PickupLocationDetailPage" */ '@/app/ui/views/PickUpLocation/Detail/index.vue'
          ),
        meta: {
          title: 'Detail Location',
          slugFromBackend: BACKEND_SLUG.PICKUP_LOCATION,
          accessType: 'read',
        }
      }
    ]
  },
  search
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
